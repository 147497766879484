import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
  clientId: "admin-client",
  realm: "Adsoftware",
  url: "https://keycloak.adsoftware-tech.com/auth",
  "enabled-cors": true,
  "public-client": true
});

export default keycloak;
