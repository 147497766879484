import React from "react";
import {
    Typography,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        '& > *:nth-child(1)': {
            marginRight: theme.spacing(1)
        },
    },
    warning: {
        color: theme.palette.warning.main,
    },
    info: {
        color: theme.palette.primary.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
}));

BaseMessage.propTypes = {
    messages: PropTypes.any,
    showIcon: PropTypes.bool,
    className: PropTypes.any,
    type: PropTypes.string.isRequired
};

export default function BaseMessage({ messages, showIcon, className, type }) {
    const classes = useStyles();
    const eMessages = Array.isArray(messages) ? messages : [messages] || [];
    return eMessages.length && eMessages.map(message => <Grid container className={className ? clsx(classes[type], className, classes.root) : clsx(classes[type], classes.root)}>
        {showIcon && type === "warning" && <WarningIcon fontSize={"small"} />}
        {showIcon && type === "info" && <InfoIcon fontSize={"small"} />}
        {showIcon && type === "success" && <CheckIcon fontSize={"small"} />}
        {showIcon && type === "error" && <ErrorOutlineIcon fontSize={"small"} />}
        <Typography variant={"caption"} >
            {message}
        </Typography>
    </Grid>);
}