import { createTheme } from "@material-ui/core/styles";
import { red, indigo } from "@material-ui/core/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: indigo,
    error: red,
    success: {
      main: "#558b2f",
      contrastText: "#ffffff",
      dark: "#33691e"
    },
    warning: {
      main: "#ff6f00",
      contrastText: "#ffffff",
      dark: "#e65100"
    }
  },
  mixins: {
    toolbar: 300
  }
});

export default theme;
