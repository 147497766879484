import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Container,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
  Select,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Yup, { REQUIRED_FIELD_MESSAGE } from "../../utils/CustomYup";
import gql from "graphql-tag";
import Errors from "../../utils/Errors";
import Info from "../../utils/Info"
import { Formik } from "formik";
import ProgressButton from "../../utils/ProgressButton";
import { getDicLatestVersionForVersions } from "../../utils/semVer";
import { compare as compareSemVer, coerce } from "semver";

const customerUrlSchema = Yup.object().shape({
  customerTrigram: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  stage: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  versionName: Yup.string().required(REQUIRED_FIELD_MESSAGE)
});

const customerUrlInitialValues = {
  customerTrigram: "",
  stage: "PROD",
  versionName: "",
};

const GET_AWA_VERSIONS = gql`
  {
    AWAVersions {
      id
      name
      deletedAt
      moduleVersions {
        id
        name
        moduleId
      }
    }
  }
`;

const UPDATE_CUSTOMER_URL = gql`
  mutation updateCustomerUrl($url: AwaCustomerUrlInput! ) {
    updateCustomerUrl(url: $url)
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  success: {
    padding: theme.spacing(2),
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    marginBottom: theme.spacing(2)
  },
  error: {
    padding: theme.spacing(2),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginBottom: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  messageEdata: {
    marginTop: theme.spacing(2)
  }
}));

export default function AWAUrl() {
  const classes = useStyles();
  const [dicVersionsLatest, setDicVersionsLatest] = React.useState({});
  const { data: dataVersions, error: errorVersions, loading: loadingVersions } = useQuery(GET_AWA_VERSIONS);
  const [updateCustomerUrl, { data, error, loading }] = useMutation(UPDATE_CUSTOMER_URL, {});

  function formatVersion() {
    return getDicLatestVersionForVersions(dataVersions.AWAVersions);
  }
  React.useEffect(() => {
    if (dataVersions && dataVersions.AWAVersions) {
      setDicVersionsLatest(formatVersion())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVersions])
  return (
    <Container maxWidth="lg">
      {error && <Paper className={classes.error}>{error.message}</Paper>}
      {errorVersions && <Paper className={classes.error}>{errorVersions.message}</Paper>}
      {data && <Paper className={classes.success}>Customer updated</Paper>}
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" className={classes.title}>
          Manage customer's AWA url
        </Typography>
        {
          loadingVersions &&
          <CircularProgress data-testid="loading" />
        }
        {
          !loadingVersions && <Formik
            initialValues={customerUrlInitialValues}
            validationSchema={customerUrlSchema}
          >
            {formik => (
              <>
                <Grid item xl={12}>
                  <Info className={classes.messageEdata} showIcon={true} messages={"This will redirect awa-TRIG.adsoftware.fr on the right AWA version"} />
                  <TextField
                    required
                    id="customerTrigram"
                    name="customerTrigram"
                    label="Customer trigram"
                    fullWidth
                    value={formik.values.customerTrigram}
                    onChange={formik.handleChange}
                    error={formik.errors.customerTrigram}
                  />
                  <Errors messages={formik.errors.customerTrigram} />
                  <FormControl fullWidth required>
                    <InputLabel
                      error={formik.errors.versionName} htmlFor={"select-version"}>Awa version</InputLabel>
                    <Select
                      id="versionName"
                      name="versionName"
                      value={formik.values.versionName}
                      onChange={formik.handleChange}
                      error={formik.errors.versionName}
                    >
                      {Object.entries(dicVersionsLatest)
                        .sort(([a], [b]) => compareSemVer(coerce(b), coerce(a))).map(([name]) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Errors messages={formik.errors.versionName} />
                  <ProgressButton
                    className={classes.buttons}
                    label={`Update ${formik.values.stage} customer url ${formik.values.customerTrigram} to redirect on AWA version ${formik.values.versionName} `}
                    action={async () => {
                      const valid = await formik.validateForm();
                      if (!valid) {
                        return;
                      }
                      await updateCustomerUrl({ variables: { url: formik.values } });
                    }}
                    busy={loading}
                  />
                </Grid>
              </>
            )}
          </Formik>
        }
      </Paper>
    </Container>
  );
}