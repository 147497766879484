import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AWAVersions from "../awa/versions/AWAVersions";
import AWAHistoryVersions from "../awa/versions/AWAHistoryVersions";
import Monitoring from "../monitoring/Monitoring";
import Customers from "../customer/Customers";
import MantisAffectedToSupport from "../mantis/MantisAffectedToSupport";
import { AWSMonitor } from "../aws/AWSMonitor";
import QuotasProvider from "./QuotasContext";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    "& > *": {
      display: "flex",
      margin: 15,
      flexDirection: "column",
      "& > *": {
        marginBottom: 15,
      },
    },
  },
  mediumContainer: {
    flexBasis: "31%",
  },
  largeContainer: {
    flexBasis: "68%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const awaVersionPaper = clsx(classes.paper);
  return (
    <QuotasProvider>
      <div className={classes.container}>
        <div className={classes.mediumContainer}>
          <Paper className={awaVersionPaper}>
            <MantisAffectedToSupport />
          </Paper>
          <Paper className={awaVersionPaper}>
            <AWAVersions />
          </Paper>
          <Paper className={awaVersionPaper}>
            <Customers />
          </Paper>
        </div>
        <div className={classes.largeContainer}>
          <Paper className={classes.paper}>
            <Monitoring />
          </Paper>
          <Paper className={classes.paper}>
            <AWAHistoryVersions />
          </Paper>
          <Paper className={classes.paper}>
            <AWSMonitor />
          </Paper>
        </div>
      </div>
    </QuotasProvider>
  );
}
