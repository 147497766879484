import { ThemeProvider } from "@material-ui/styles";
import React, { useState } from "react";
import SiteTemplate from "./general/SiteTemplate";
import theme from "./theme";
// import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import config from "./config";
import { LoggedContext } from "./LoggedContext";
import { KTOKEN } from "./utils/constants";
import UserProvider from "./utils/UserProvider";
import Secured from "./general/Secured";

const urlLink = new HttpLink({
  uri: config.SERVER_URL
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(KTOKEN);
  return {
    headers: {
      ...headers,
      "x-access-token": token || ""
    }
  };
});
const httpLink = new ApolloLink.from([authLink, urlLink]);
const wsLink = new WebSocketLink({
  uri: config.WS_SERVER_URL,
  options: {
    reconnect: true
  }
});
const authErroLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        if (
          message === "Context creation failed: jwt must be provided" &&
          !localStorage.getItem(KTOKEN)
        ) {
          localStorage.removeItem(KTOKEN);
          navigate("/login", { replace: true });
        }

        if (
          (message === "Context creation failed: invalid token" ||
            message === "Context creation failed: jwt expired") &&
          localStorage.getItem(KTOKEN)
        ) {
          localStorage.removeItem(KTOKEN);
          navigate("/login", { replace: true });
        }
      });
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: ApolloLink.from([authErroLink(), link]),
  cache: new InMemoryCache()
});

export default function App() {
  const [logged, setStateLogged] = useState(!!localStorage.getItem(KTOKEN));

  function setLogged(token) {
    if (!token) {
      setStateLogged(false);
      localStorage.removeItem(KTOKEN);
    } else {
      setStateLogged(true);
      localStorage.setItem(KTOKEN, token);
    }
  }
  return (
    <Secured
      onTokenReceived={token => {
        setLogged(token);
      }}
    >
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <LoggedContext.Provider value={{ logged, setLogged }}>
            <UserProvider>
              <SiteTemplate />
            </UserProvider>
          </LoggedContext.Provider>
        </ApolloProvider>
      </ThemeProvider>
    </Secured>
  );
}
