import React from "react";
import {
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";

export default function Finalize({ formik }) {
  const {
    values
  } = formik;

  const modules = Object.entries(formik.values.dicModules).reduce((acc, [moduleId, moduleName]) => {
    if(moduleName === "monorepo") return acc;
    const moduleVersion = formik.values.modules[moduleId];
    if(moduleVersion) return [
      ...acc,
      {
        name: moduleName,
        version: moduleVersion
      }
    ];

    return [
      ...acc,
      {
        name: `${moduleName}-monorepo`,
        version: null
      }
    ]
  }, []);

  const monorepoModule = React.useMemo(() => {
    const [monorepoId, monorepoName] = Object.entries(formik.values.dicModules).find(e => e[1] === "monorepo") || [];
    const monorepo = Object.entries(formik.values.modules).find(([moduleId, ]) => moduleId === monorepoId);
    if(!monorepo) return null;
    return {
      name: monorepoName,
      version: monorepo[1],
    }
  }, [formik]);

  

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Finalize
      </Typography>
      <Grid container spacing={3}>
        <Grid item xl={6}>
          <TextField
            id="name"
            name="name"
            label="Name"
            fullWidth
            value={values.name}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
        {monorepoModule &&
          <Grid key={monorepoModule.name} item xl={6}>
            <TextField
              id={`module-${monorepoModule.name}`}
              name={`module-${monorepoModule.name}`}
              fullWidth
              label={monorepoModule.name}
              value={monorepoModule.version}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>}
        <Grid item xl={12}>
          <TextField
            id="description"
            name="description"
            label="Description"
            fullWidth
            value={values.description}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
        <Grid
          item
          container
          spacing={1}
        >
          {modules.map(module => (
            <Grid key={module.name} item>
              <TextField
                id={`module-${module.name}`}
                name={`module-${module.name}`}
                fullWidth
                label={module.name}
                value={module.version}
                defaultValue={module.name}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

Finalize.propTypes = {
  formik: PropTypes.object.isRequired
};
