import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  AWSQuotas,
  AWS_ACCOUNT_USAGE_INTERVAL_SECONDS,
  WARN_MAX_USAGE_PERCENT,
} from "../utils/constants";
import { CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import { QuotasContext } from "../general/QuotasContext";
import theme from "../theme";

export const AWSS3UsageWidget = ({ profile }) => {
  const [quotasAreReached, setReached] = useContext(QuotasContext);

  const getAWSUsageStats = gql`
    query($profile: String) {
      AWSUsageStats(profile: $profile) {
        BucketsStats {
          amount
        }
      }
    }
  `;
  const {
    loading,
    error,
    data: {
      AWSUsageStats: { BucketsStats: { amount: bucketsAmount } = {} } = {},
    } = {},
  } = useQuery(getAWSUsageStats, {
    variables: { profile },
    pollInterval: AWS_ACCOUNT_USAGE_INTERVAL_SECONDS * 1000,
  });

  useEffect(() => {
    if (quotasAreReached) return;
    setReached(
      (bucketsAmount / AWSQuotas.s3[profile]) * 100 >= WARN_MAX_USAGE_PERCENT
    );
  }, [bucketsAmount, profile, setReached, quotasAreReached]);

  return (
    <div>
      {loading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {error && error.message}
      {!loading && !error && (
        <div>
          <LinearProgress
            variant="determinate"
            value={(bucketsAmount / AWSQuotas.s3[profile]) * 100}
            color={
              (bucketsAmount / AWSQuotas.s3[profile]) * 100 <
              WARN_MAX_USAGE_PERCENT
                ? "primary"
                : "secondary"
            }
          />
          <span>{`Buckets : ${bucketsAmount} / `}</span>
          <span
            style={{ color: theme.palette.warning.main }}
          >{`${AWSQuotas.s3[profile]}`}</span>
        </div>
      )}
    </div>
  );
};
