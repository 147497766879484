import React, { useState } from "react";
import {
    TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
TypingDurationTextField.propTypes = {
    onFinishTyping: PropTypes.func,
    timeout: PropTypes.number
};

export default function TypingDurationTextField(props) {
    const { onFinishTyping, timeout } = props;
    const [idle, setIdle] = useState(null);
    return <TextField
        {...props}
        onKeyUp={() => {
            clearTimeout(idle);
            setIdle(
                setTimeout(() => {
                    if (idle && onFinishTyping) {
                        onFinishTyping()
                    }
                }, timeout || 400))
        }}
        onBlur={() => {
            clearTimeout(idle);
            onFinishTyping();
        }}
        onKeyDown={() => {
            if (idle) {
                clearTimeout(idle);
            }
        }}
    />
}