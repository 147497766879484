import React from "react";
import { useToggle } from "react-use";
import { CssBaseline, makeStyles } from "@material-ui/core";
import Header from "./Header";
import Sidemenu from "./Sidemenu";
import Main from "./Main";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  appBarSpacer: {
    height: 80
  }
}));

export default function SiteTemplate() {
  const classes = useStyles();
  const [sidebarOpen, toggleSidebar] = useToggle(true);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <Sidemenu sidebarOpen={sidebarOpen} closeSidebar={toggleSidebar} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Main />
      </main>
    </div>
  );
}
