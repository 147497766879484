import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Container,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Formik } from "formik";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import { groupBy, sortBy } from "lodash";
import Errors from "../utils/Errors"
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Yup, { REQUIRED_FIELD_MESSAGE } from "../utils/CustomYup";
import ProgressButton from "../utils/ProgressButton";
import { getDicLatestVersionForVersions } from "../utils/semVer";
import { compare as compareSemVer, coerce, valid } from "semver";

const UPDATE_CUSTOMER_VERSION_STAGE = gql`
  mutation updateCustomerVersionStage($updateCVS: CVSInput!) {
    updateCustomerVersionStage(updateCVS: $updateCVS)
  }
`;

const GET_CUSTOMER_VERSIONS = gql`
  query {
    AWACustomerVersions {
      id
      trigram
      airpackVersion
      awaVersion
      stage
    }
  }
`;

const GET_AWA_VERSIONS = gql`
  {
    AWAVersions {
      id
      name
      deletedAt
      moduleVersions {
        id
        name
        moduleId
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    padding: 0,
  },
  saveCircularButton: {
    width: "20px !important",
    height: "20px !important",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    position: "relative",
  },
  current: {
    backgroundColor: "#ffccbc",
  },
  success: {
    padding: theme.spacing(2),
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    marginBottom: theme.spacing(2)
  },
  error: {
    padding: theme.spacing(2),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginBottom: theme.spacing(2)
  },
}));

const versionSchema = Yup.object().shape({
  airpackVersion: Yup.string().required(REQUIRED_FIELD_MESSAGE).test("not-valid", "Not a valid semver version", value => valid(value)),
  awaVersion: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  customerTrigram: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  stage: Yup.string().required(REQUIRED_FIELD_MESSAGE),
});
const initialValues = {
  airpackVersion: "",
  awaVersion: "",
  customerTrigram: "",
  stage: "",
}

export default function AwaVersionCustomer() {
  const classes = useStyles();

  const awaVersions = useQuery(GET_AWA_VERSIONS);
  const [deleteIds, setDeleteIds] = useState([]);
  const [deleteOutdatedEnv, setDeletedOutdatedEnv] = useState(false);
  const customersVersions = useQuery(GET_CUSTOMER_VERSIONS, {
    fetchPolicy: "no-cache",
  });
  const [updateCVS, { data, loading, error }] = useMutation(UPDATE_CUSTOMER_VERSION_STAGE);
  function formatVersion() {
    const glv = getDicLatestVersionForVersions(awaVersions.data.AWAVersions);
    return glv;
  }
  function formatCVS() {
    const gcvs = groupBy(customersVersions.data.AWACustomerVersions, c => c.trigram);
    Object.entries(gcvs).map(([key, value]) => gcvs[key] = sortBy(value, ["stage"]))
    return gcvs;
  }
  const dicVersionsLatest = awaVersions.data && awaVersions.data.AWAVersions ? formatVersion() : {};
  const cvsByCustomer = customersVersions.data && customersVersions.data.AWACustomerVersions
    ? formatCVS(groupBy(customersVersions.data.AWACustomerVersions, c => c.trigram))
    : {};

  return (
    <Container maxWidth="lg">
      {error && <Paper className={classes.error}>{error.message}</Paper>}
      {data && <Paper className={classes.success}>Customer updated</Paper>}
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" className={classes.title}>
          Upsert customer versions for stage
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={versionSchema}
          validateOnChange={false}
        >
          {formik => (
            <>
              <ExpansionPanel
                defaultExpanded={true}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-form-content"
                  id="panel-form-header"
                >
                  <Typography className={classes.heading}>General</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container>
                    <Grid item xl={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="customerTrigram"
                          name="customerTrigram"
                          label="Customer trigram"
                          fullWidth
                          required
                          value={formik.values.customerTrigram}
                          onChange={formik.handleChange}
                          error={formik.errors.customerTrigram}
                        />
                      </FormControl>
                      <Errors messages={formik.errors.customerTrigram} />
                    </Grid>
                    <Grid item xl={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="stage"
                          name="stage"
                          label="Stage"
                          fullWidth
                          required
                          value={formik.values.stage}
                          onChange={formik.handleChange}
                          error={formik.errors.stage}
                        />
                      </FormControl>
                      <Errors messages={formik.errors.stage} />
                    </Grid>
                    <Grid item xl={12}>
                      <FormControl fullWidth required>
                        <InputLabel
                          error={formik.errors.stage} htmlFor={"select-stage-version"}>Awa version</InputLabel>
                        <Select
                          id="awaVersion"
                          name="awaVersion"
                          value={formik.values.awaVersion || ""}
                          onChange={formik.handleChange}
                          error={formik.errors.awaVersion}
                        >
                          {Object.entries(dicVersionsLatest)
                            .sort(([a], [b]) => compareSemVer(coerce(b), coerce(a))).map(([name]) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Errors messages={formik.errors.awaVersion} />
                    </Grid>
                    <Grid item xl={12}>
                      <FormControl fullWidth required>
                        <TextField
                          id="airpackVersion"
                          name="airpackVersion"
                          label="Airpack version"
                          fullWidth
                          required
                          value={formik.values.airpackVersion}
                          onChange={formik.handleChange}
                          error={formik.errors.airpackVersion}
                        />
                      </FormControl>
                      <Errors messages={formik.errors.airpackVersion} />
                    </Grid>
                    <Grid item xl={12}>
                      <FormControlLabel
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        control={
                          <Switch
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            checked={deleteOutdatedEnv}
                            onChange={() => {
                              setDeletedOutdatedEnv(!deleteOutdatedEnv)
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            value={`switch-update-auto-env`}
                          />
                        }
                        label={`Delete outdated autonomous env`}
                      />
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                defaultExpanded={true}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-customer-content"
                  id="panel-customer-header"
                >
                  <Typography className={classes.heading}>Existing version stage for customer {formik.values.customerTrigram}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Stage</TableCell>
                        <TableCell>Awa Version</TableCell>
                        <TableCell>Airpack Version</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        cvsByCustomer && cvsByCustomer[formik.values.customerTrigram] &&
                        cvsByCustomer[formik.values.customerTrigram].map(({ id, awaVersion, airpackVersion, stage }) => (
                          <TableRow key={id} className={stage === formik.values.stage ? classes.current : ""}>
                            <TableCell>{stage}</TableCell>
                            <TableCell>{awaVersion}</TableCell>
                            <TableCell>{airpackVersion}</TableCell>
                            <TableCell>
                              {
                                deleteIds.includes(id) &&
                                <CircularProgress classes={{ root: classes.saveCircularButton }} />
                              }
                              {
                                !deleteIds.includes(id) &&
                                <Tooltip title="Delete entry">
                                  <IconButton
                                    className={classes.deleteIcon}
                                    data-testid="deleteButton"
                                    onClick={async () => {
                                      setDeleteIds([...deleteIds, id]);
                                      await updateCVS({
                                        variables: {
                                          updateCVS: {
                                            customerTrigram: formik.values.customerTrigram,
                                            stage,
                                            awaVersion,
                                            awaVersionId: "0",
                                            airpackVersion,
                                            deleteOutdatedEnv,
                                            deleted: true,
                                          }
                                        }
                                      });
                                      setDeleteIds([]);
                                      setTimeout(async () => { await customersVersions.refetch() }, 500);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <Grid item className={classes.buttons}>
                <ProgressButton
                  label={`Upsert customer ${formik.values.customerTrigram} to version ${formik.values.awaVersion} and ${formik.values.airpackVersion} on ${formik.values.stage}`}
                  action={async () => {
                    const errors = await formik.validateForm();
                    const version = dicVersionsLatest[formik.values.awaVersion];
                    if (Object.entries(errors).length !== 0 || !version) {
                      formik.setStatus({ errors });
                    } else {
                      await updateCVS({
                        variables: {
                          updateCVS: {
                            customerTrigram: formik.values.customerTrigram,
                            stage: formik.values.stage,
                            awaVersion: formik.values.awaVersion,
                            deleteOutdatedEnv,
                            awaVersionId: version.id,
                            airpackVersion: formik.values.airpackVersion,
                            deleted: false,
                          }
                        }
                      });
                      await new Promise((res, rej) => {
                        setTimeout(() => res(true), 1000)
                      });
                      return customersVersions.refetch();
                    }
                  }}
                  busy={loading}
                />
              </Grid>
            </>
          )}
        </Formik>
      </Paper>
    </Container>
  );
}
