import React from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BaseIconTextButton from './BaseIconTextButton';
import { navigate } from "@reach/router";
export default function DeleteBetaButton({ disabled }) {
  return (
    <BaseIconTextButton
      label={"Beta"}
      tooltip={"Delete beta"}
      disabled={disabled}
      icon={<HighlightOffIcon />}
      onClick={() => navigate(`/awa/delete/stage/beta`)}
    />
  );
}

DeleteBetaButton.propTypes = {
  disabled: PropTypes.any,
};
