import React, { useContext } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LoginButton from "./LoginButton";
import OtherButton from "./OtherButton";
import { SIDEMENU_WIDTH } from "../utils/constants";
import PropTypes from "prop-types";
import { UserContext } from "../utils/UserProvider"

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: SIDEMENU_WIDTH,
    width: `calc(100% - ${SIDEMENU_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  su: {
    color: "red"
  },
  title: {
    flexGrow: 1
  }
}));

export default function Header({ sidebarOpen, toggleSidebar }) {
  const classes = useStyles();
  const { su } = useContext(UserContext);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, sidebarOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Open drawer"
          onClick={toggleSidebar}
          className={clsx(
            classes.menuButton,
            sidebarOpen && classes.menuButtonHidden
          )}
          data-testid={sidebarOpen ? "sidebarOpenIcon" : "sidebarCloseIcon"}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          ADMin
          {
            su && <>
              &nbsp;- <span
                className={classes.su}>
                SuperUser
            </span>
            </>
          }
        </Typography>
        <OtherButton />
        <LoginButton />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};
