import React from "react";
import {
    Grid,
    ExpansionPanelSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Log from "../log/Log";

const useStyles = makeStyles(theme => ({
    sticky: {
        position: "sticky",
        top: "70px",
        maxHeight: "calc(100vh - 200px)",
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flexBasis: "100%"
    },
    contentSmall: {
        flexBasis: "60%"
    },
    logContainer: {
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse",
        padding: "10px"
    },
    logs: {
        flexBasis: "40%"
    },
    headerPanel: {
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)",
    },
}));
export default function LogSidebar({ children, log }) {
    const classes = useStyles();
    return (<Grid container item spacing={5} style={{ flexWrap: "nowrap" }}>
        <Grid item className={log ? classes.contentSmall : classes.content}>
            {
                children
            }
        </Grid>
        {
            log && <Grid item className={classes.logs}>
                <div className={classes.sticky}>
                    <ExpansionPanelSummary
                        className={classes.headerPanel}
                    >
                        Logs
                    </ExpansionPanelSummary>
                    <div className={classes.logContainer}>
                        {
                            (log && log.data && log.data.addLog) &&
                            <Log sticky log={log.data.addLog} />
                        }
                    </div>
                </div>
            </Grid>
        }
    </Grid>)
}