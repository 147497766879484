import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  AWSQuotas,
  AWS_ACCOUNT_USAGE_INTERVAL_SECONDS,
  WARN_MAX_USAGE_PERCENT
} from '../utils/constants';
import { CircularProgress, Grid, LinearProgress } from '@material-ui/core';
import { QuotasContext } from '../general/QuotasContext';
import theme from '../theme';

export const AWSGatewayUsageWidget = ({ profile }) => {
  const [quotasAreReached, setReached] = useContext(QuotasContext);

  const getAWSUsageStats = gql`
    query($profile: String) {
      AWSUsageStats(profile: $profile) {
        GatewayStats {
          edge
          regional
        }
      }
    }
  `;
  const {
    loading,
    error,
    data: { AWSUsageStats: { GatewayStats: { edge, regional } = {} } = {} } = {}
  } = useQuery(getAWSUsageStats, {
    variables: { profile },
    pollInterval: AWS_ACCOUNT_USAGE_INTERVAL_SECONDS * 1000
  });

  useEffect(() => {
    if (quotasAreReached) return;
    setReached(
      (edge / AWSQuotas.apiGateway.edge) * 100 >= WARN_MAX_USAGE_PERCENT ||
        (regional / AWSQuotas.apiGateway.regional) * 100 >=
          WARN_MAX_USAGE_PERCENT
    );
  }, [edge, regional, profile, quotasAreReached, setReached]);

  console.log();

  return (
    <div>
      {loading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {error && error.message}
      {!loading && !error && (
        <div>
          <LinearProgress
            variant="determinate"
            value={(edge / AWSQuotas.apiGateway.edge) * 100}
            color={
              (edge / AWSQuotas.apiGateway.edge) * 100 >= WARN_MAX_USAGE_PERCENT
                ? 'secondary'
                : 'primary'
            }
          />
          <span>{`Edge : ${edge} / `}</span>
          <span
            style={{ color: theme.palette.warning.main }}
          >{`${AWSQuotas.apiGateway.edge}`}</span>
          <LinearProgress
            variant="determinate"
            value={(regional / AWSQuotas.apiGateway.regional) * 100}
            color={
              (regional / AWSQuotas.apiGateway.regional) * 100 >=
              WARN_MAX_USAGE_PERCENT
                ? 'secondary'
                : 'primary'
            }
          />
          <span>{`Regional : ${regional} / `}</span>
          <span
            style={{ color: theme.palette.warning.main }}
          >{`${AWSQuotas.apiGateway.regional}`}</span>
        </div>
      )}
    </div>
  );
};
