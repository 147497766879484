import React from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

export default function DeleteVersionButton({ versionId, disabled }) {
  return (
    <Tooltip title="Delete version">
      <IconButton 
        data-testid="deleteButton" 
        onClick={ () => navigate(`/awa/versions/delete/${versionId}`)}
        disabled={!!disabled}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
}

DeleteVersionButton.propTypes = {
  versionId: PropTypes.string.isRequired,
  disabled: PropTypes.any,
};
