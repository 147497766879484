import React from 'react';
import {
    Grid,
    TextField
  } from "@material-ui/core";

export default ({module}) => <Grid key={module.id} item md={10}>
<TextField
  readonly
  label={module.name}
  fullWidth
  value={module.version || "Monorepo"}
/>
</Grid>