import React from "react";
import {
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    display: "block"
  },
}));

Errors.propTypes = {
  messages: PropTypes.any
};

export default function Errors({ messages }) {
  const classes = useStyles();
  const eMessages = Array.isArray(messages) ? messages : [messages] || [];
  return eMessages.length && eMessages.map(message => <Typography variant={"caption"} className={classes.error}>
    {message}
  </Typography>);
}