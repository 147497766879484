
import * as Yup from "yup";
const {
valid
} = require("semver");
const regexWhiteSpaces = /\s/gm;
const regexNonWord = /\W/gm;

Yup.addMethod(Yup.string, 'isValidSemVer', function(message) {
    return this.test("test-semVerValid",message, function(value){
        return valid(value);
    });
});
Yup.addMethod(Yup.string, 'noWhiteSpaces', function(message) {
    return this.test("test-noWhiteSpaces",message, function(value){
        const hasWS = regexWhiteSpaces.test(value);
        const { path, createError } = this;
        return value ? hasWS ? createError({path, message}) : true : true;
    });
});
Yup.addMethod(Yup.string, 'nonWordCharacters', function(message) {
    return this.test("test-nonWordCharacters",message, function(value){
        const hasNW = regexNonWord.test(value);
        const { path, createError } = this;
        return value ? hasNW ? createError({path, message}) : true : true;
    });
});
export default Yup;

export const REQUIRED_FIELD_MESSAGE = "Required.";
export const INVALID_SEMVER = "Not a valid semver format.";
export const NO_WHITE_SPACES = "No white spaces.";
export const NON_WORD_CHARACTERS = "Only word characters [a-zA-Z0-9_].";