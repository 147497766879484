import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Grid,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
import gql from "graphql-tag";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomersVersion from "../detail/CustomersVersion";
import AWAVersionAction from "../AWAVersionAction";
import Modal from "../../../utils/Modal";
import PropTypes from "prop-types";
import ProgressButton from "../../../utils/ProgressButton";
import useAddLogMutation from "../../../log/useAddLogMutation";
const DEPLOY_VERSION = gql`
  mutation deleteAWAVersion($deleteVersion: AWADeleteVersionInput! ) {
    deleteAWAVersion(deleteVersion: $deleteVersion )
  }
`;

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
}));

DeleteVersion.propTypes = {
  id: PropTypes.string.isRequired,
};

export default function DeleteVersion({ id }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [version, setVersion] = useState({});
  const [refetch, setRefetch] = useState(() => { });
  const [deleteAWAVersion, deleteStatus] = useMutation(DEPLOY_VERSION, {
    onCompleted: (data) => {
      if (refetch) {
        refetch()
      }
    }
  });
  const [registerLog, registerLogStatus] = useAddLogMutation();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AWAVersionAction
      title={version.isPatch ? "Delete Patch " : "Delete AWA Version"}
      id={id}
      log={registerLogStatus}
      setVersion={setVersion}
      setRefetch={setRefetch}
      otherError={deleteStatus.error}
      success={deleteStatus.called === true && deleteStatus.data ? { message: deleteStatus.data.deleteAWAVersion } : null}
    >
      <Grid item xl={12}>
        <ExpansionPanel
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-description-content"
            id="panel-description-header"
          >
            <Typography className={classes.heading}>General</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {
              version.isPatch
                ? <Typography>This is a Patch Version it will only delete the version entry.</Typography>
                : <Typography>This is a Minor/Major Version it will delete all the AWS files and all the Patch version entry.</Typography>
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          defaultExpanded={true}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-customers-content"
            id="panel-customers-header"
          >
            <Typography className={classes.heading}>Customers</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {version && version.name && <CustomersVersion
              versionName={version.name}
            />}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item xl={12} className={classes.buttons}>
        <ProgressButton
          label={version.isPatch ? "Delete Patch" : "Delete Version"}
          action={handleOpen}
          busy={deleteStatus.loading}
          disabled={!!version.deletedAt}
        />
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        onValid={async () => {
          handleClose();
          const registeredLog = await registerLog({
            variables: {
              log: {
                taskReference: `delete-${id}`,
                title: `Delete Version ${version.name}`,
                status: "INFO",
              }
            }
          });
          await deleteAWAVersion({
            variables: { deleteVersion: { channelId: registeredLog.data.addLog.id, versionId: id } }
          });
        }}
        title={"Delete Version"}
        type={"warning"}
        actionName={"Delete Version"}
      >
        <Grid item>
          <Typography>Are you sure you want to delete the version {version && version.name} ?</Typography>
        </Grid>
      </Modal>
    </AWAVersionAction>
  );
}
