import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Title from "../Title";
import { CircularProgress, makeStyles, Grid } from "@material-ui/core";
import { SUPPORT_REFRESH_INTERVAL_SECONDS } from "../utils/constants";

const GET_SUPPORT_MANTIS = gql`
  query {
    mantisAffectedToSupport
  }
`;

const useStyles = makeStyles(theme => ({
  bigNumberContainer: {
    display: "flex",
    justifyContent: "center"
  },
  bigNumber: {
    fontSize: 35
  }
}));

export default function MantisAffectedToSupport() {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_SUPPORT_MANTIS, {
    pollInterval: SUPPORT_REFRESH_INTERVAL_SECONDS * 1000
  });

  return (
    <>
      <Title>Support's mantis</Title>
      {loading && <Grid 
                        container 
                        justify="center"
                    >
                        <Grid item>
                            <CircularProgress data-testid="loading" />
                        </Grid>
                    </Grid>}
      {error && error.message}
      {!loading && !error && (
        <div className={classes.bigNumberContainer}>
          <span className={classes.bigNumber}>
            {data.mantisAffectedToSupport}
          </span>
        </div>
      )}
    </>
  );
}
