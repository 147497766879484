import React, { useState } from "react";
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import CustomersVersion from "./CustomersVersion";
import ModulesVersion from "./ModulesVersion";
import { AWAHistoryVersion } from "../AWAHistoryVersions";
import AWAVersionAction from "../AWAVersionAction";
import PropTypes from "prop-types";
import LastDeploy from "./LastDeploy";
import useParseModules from "../../../utils/useParseModules";

const useStyles = makeStyles(theme => ({
  customer: {
    padding: "5px",
    borderRadius: "10px",
    borderWidth: "1px",
    borderColor: "#000000"
  }
}));

AWAVersion.propTypes = {
  id: PropTypes.string.isRequired
};

export default function AWAVersion({ id, stage = "PROD" }) {
  const classes = useStyles();

  const [version, setVersion] = useState({});
  const { modules, isMonorepoExcluded, modulesLoading } = useParseModules({
    versionModules: version.moduleVersions,
    versionName: version.name
  });
  return (
    <AWAVersionAction
      title={"AWA Version"}
      id={id}
      showDelete={true}
      showDeploy={true}
      showDeployBeta={true}
      setVersion={version => setVersion(version)}
      subtitle={
        <LastDeploy
          stage={stage}
          deployedAt={version ? version.deployedAt : null}
        />
      }
    >
      {version.id && (
        <>
          <Grid item xl={12}>
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-description-content"
                id="panel-description-header"
              >
                <Typography className={classes.heading}>General</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {(version && version.description) || "No description"}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-customer-content"
                id="panel-customer-header"
              >
                <Typography className={classes.heading}>Customers</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {version && <CustomersVersion versionName={version.name} />}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-module-content"
                id="panel-module-header"
              >
                <Typography className={classes.heading}>
                  Modules versions
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {version && (
                  <ModulesVersion
                    modules={modules}
                    loading={modulesLoading}
                    version={version}
                    isMonorepoExcluded={isMonorepoExcluded}
                    select={false}
                  />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-history-content"
                id="panel-history-header"
              >
                <Typography className={classes.heading}>History</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: "block" }}>
                {version && <AWAHistoryVersion id={version.id} />}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </>
      )}
    </AWAVersionAction>
  );
}
