import { Alert } from "@material-ui/lab";
import React, { useContext } from "react";
import { QuotasContext } from "../general/QuotasContext";
import Title from "../Title";
import { AWSAccountUsageWidget } from "./AWSAccountUsageWidget";
import { AWSGatewayUsageWidget } from "./AWSGatewayUsageWidget";
import { AWSS3UsageWidget } from "./AWSS3UsageWidget";
import theme from "../theme";
import { AWSSNSUsage } from "./AWSSNSUsage";
import { eAWSProfiles } from "../utils/constants";

export const AWSMonitor = () => {
  const [quotasAreReached] = useContext(QuotasContext);

  const grid = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: "20px"
  };
  return (
    <>
      <Title>AWS Quotas</Title>
      {quotasAreReached && (
        <Alert variant="filled" severity="error">
          Certains quotas sont remplis ! Faites de la place avant de créer un
          nouveau déploiement !
        </Alert>
      )}
      <div style={grid}>
        <h2>Development</h2>
        <h2>Staging</h2>
        <h2>Production</h2>
      </div>
      <h3>Code</h3>
      <div style={grid}>
        <AWSAccountUsageWidget profile={eAWSProfiles.DEV} />
        <AWSAccountUsageWidget profile={eAWSProfiles.STAGING} />
        <AWSAccountUsageWidget profile={eAWSProfiles.PROD} />
      </div>
      <h3>S3</h3>
      <div style={grid}>
        <AWSS3UsageWidget profile={eAWSProfiles.DEV} />
        <AWSS3UsageWidget profile={eAWSProfiles.STAGING} />
        <AWSS3UsageWidget profile={eAWSProfiles.PROD} />
      </div>
      <h3>Api Gateway</h3>
      <div style={grid}>
        <AWSGatewayUsageWidget profile={eAWSProfiles.DEV} />
        <AWSGatewayUsageWidget profile={eAWSProfiles.STAGING} />
        <AWSGatewayUsageWidget profile={eAWSProfiles.PROD} />
      </div>
      <h3>SNS (Global)</h3>
      <AWSSNSUsage profile={eAWSProfiles.GENERAL} />
      <p style={{ color: theme.palette.warning.main }}>
        Chiffres en dur, si le quota est manuellement augmenté sur AWS, penser à
        venir changer la constante.
      </p>
    </>
  );
};
