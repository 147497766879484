import React from 'react';
import {
  makeStyles,
  Container,
  Paper,
  Typography,
  Checkbox,
  Chip
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { AWS_ACCOUNT_USAGE_INTERVAL_SECONDS } from '../utils/constants';
import {
  DataGrid,
  Column,
  FilterRow,
  SearchPanel,
  Pager,
  Grouping,
  Summary,
  GroupItem,
  Paging
} from 'devextreme-react/data-grid';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

export const Deployments = () => {
  const classes = useStyles();

  const getDeployments = gql`
    query {
      AWSDeployments {
        id
        createdDate
        stage
        awaStage
        awaUrl
        apiGateway
        db
        dbExist
        endpointConfiguration
        name
        url
      }
    }
  `;

  const {
    loading,
    error,
    data: { AWSDeployments: deployments = {} } = {}
  } = useQuery(getDeployments, {
    pollInterval: AWS_ACCOUNT_USAGE_INTERVAL_SECONDS * 1000
  });

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :/</p>;
  }
  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" className={classes.title}>
          Deployments
        </Typography>
        <DataGrid
          dataSource={deployments}
          rowKey="id"
          allowColumnResizing
          allowColumnReordering
          showBorders
          rowAlternationEnabled
          columnAutoWidth
        >
          <Paging defaultPageSize={25} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[25, 50]}
            showInfo={true}
          />
          <Grouping autoExpandAll={false} />
          <SearchPanel visible />
          <FilterRow visible />
          <Pager
            showPageSizeSelector
            allowedPageSizes={[5, 10, 20, 50, 100]}
            showInfo
          />
          <Column
            dataField="awaStage"
            caption="Name"
            groupIndex={0}
            groupCellRender={entity => {
              if (!entity) return '';
              const {
                value,
                row: {
                  data: { collapsedItems, items }
                },
                summaryItems: [{ value: sumValue }]
              } = entity;
              let apiGateway, stage, awaUrl;
              if (collapsedItems) {
                apiGateway = collapsedItems[0].apiGateway;
                stage = collapsedItems[0].stage;
                awaUrl = collapsedItems[0].awaUrl;
              }
              if (items) {
                apiGateway = items[0].apiGateway;
                stage = items[0].stage;
                awaUrl = items[0].awaUrl;
              }
              return (
                <>
                  <div style={{ display: 'inline-block', marginRight: '20px' }}>
                    <a href={awaUrl} target="_blank" rel="noopener noreferrer">
                      {value}
                    </a>
                    <br />
                    api-gateway: {apiGateway}, lambdas: {sumValue}
                  </div>
                  <Chip label={stage} color="default" />
                </>
              );
            }}
          />
          <Column
            dataField="name"
            caption="Name"
            defaultSortIndex={1}
            defaultSortOrder="asc"
            cellRender={({
              row: {
                data: { name, url }
              }
            }) => (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            )}
          />
          <Column
            dataField="createdDate"
            dataType="date"
            caption="Creation date"
            cellRender={({
              row: {
                data: { createdDate }
              }
            }) => dayjs(createdDate).format('DD MMM YYYY')}
            defaultSortIndex={0}
            defaultSortOrder="desc"
          />
          <Column
            dataField="db"
            caption="Should database exist"
            cellRender={({
              row: {
                data: { db }
              }
            }) => <Checkbox checked={db} disabled />}
          />
          <Column
            dataField="dbExist"
            caption="Database exist"
            cellRender={({
              row: {
                data: { dbExist }
              }
            }) => <Checkbox checked={dbExist} disabled />}
          />
          <Summary>
            <GroupItem column="Id" summaryType="count" />
          </Summary>
        </DataGrid>
      </Paper>
    </Container>
  );
};
