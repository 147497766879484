import React, { useState } from "react";
import { GET_AWA_VERSIONS } from "./AWAVersions";
import { useQuery } from "@apollo/react-hooks";
import DeleteVersionButton from "./DeleteVersionButton";
import {
  Grid,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  makeStyles,
  Typography
} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles({
  title: {
    padding: "20px",
    marginTop: "30px"
  },

  table: {
    margin: "20px"
  }
});

export default function AWATemporaryVersions() {
  const classes = useStyles();
  const [rowId, setRowId] = useState(null);
  const { loading, error, data } = useQuery(GET_AWA_VERSIONS, {
    fetchPolicy: "no-cache"
  });
  const temporaryVersions = data?.AWAVersions?.filter(version => {
    if (!!version.deletedAt) return false;
    const regex = /[A-Z]/gi;
    return regex.test(version.name);
  }).sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return (
    <>
      {loading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {error && (
        <Grid container justify="center">
          <Grid item>
            <Paper>{error.message}</Paper>
          </Grid>
        </Grid>
      )}
      {!loading && !error && (
        <Grid container justify="left">
          <Grid item className={classes.table} xs={12} md={6}>
            <Typography component="h1" variant="h4" className={classes.title}>
              {temporaryVersions?.length} Temporary Versions
            </Typography>
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">Version Name</TableCell>
                    <TableCell align="left">
                        created At
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {temporaryVersions?.map(({ id, name, createdAt }) => (
                    <TableRow
                      key={id}
                      onClick={() => setRowId(id)}
                      selected={id === rowId}
                    >
                      <TableCell width="50px">
                        <DeleteVersionButton
                          versionId={rowId ? rowId : null}
                          disabled={id !== rowId}
                        />
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        {moment(createdAt).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
