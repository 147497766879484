import React, { useContext } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Tooltip, IconButton } from "@material-ui/core";
import { navigate } from "@reach/router";
import { QuotasContext } from "../../general/QuotasContext";

export default function AddVersionButton() {
  const [quotasAreReached] = useContext(QuotasContext);

  const handleClick = () => {
    let confirm = true;
    if (quotasAreReached) {
      confirm = window.confirm("Certains quotas sont remplis. Continuer ?");
    }
    confirm && navigate("/awa/versions/new");
  };

  return (
    <>
      <Tooltip title="Create new version">
        <IconButton onClick={handleClick}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
