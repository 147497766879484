import { compare, major, minor, valid, prerelease } from "semver";

export function getDicLatestVersionForVersions(versions, keepPrerelease = false) {
  const rootVersions = versions.filter(version => !(version.deletedAt))
    .map(version => ({ ...version, root: `${major(version.name)}.${minor(version.name)}` }));
  return rootVersions.reduce((a, v) => {
    if (keepPrerelease) {
      a[v.name] = v;
      return a;
    }
    if (a[v.root]) {
      const c = compare(a[v.root].name, v.name);
      a[v.root] = c === -1 ? v : a[v.root];
      return a;
    } else {
      a[v.root] = v;
      return a;
    }
  }, {});
}

export function getRootVersion(name) {
  return valid(name) ? `${major(name)}.${minor(name)}` : null;
}

export function getPreviousVersion(version, versions) {
  if (!version.isPatch) {
    return null;
  }
  const isPrerelease = prerelease(version.name);
  const rootVersion = getRootVersion(version.name);
  const filteredVersion = versions.filter(v => !(v.name === version.name)).filter(v => isPrerelease ? !!prerelease(v.name) : !prerelease(v.name));
  if (!filteredVersion.length) {
    return null;
  }
  const dic = getDicLatestVersionForVersions(filteredVersion);
  return dic[rootVersion];
}