import React, { useState, useCallback } from "react";
import clsx from "clsx";
import {
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  ListItemIcon,
  Badge,
  Grid,
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { IconButton } from "@material-ui/core";
import { useSubscription } from '@apollo/react-hooks';
import gql from "graphql-tag";
import PropTypes from "prop-types";
import moment from "moment";

const SUBSCRIBE_LOG = gql`
  subscription logAdded($channelId: ID) {
    logAdded(channelId: $channelId) {
      id
      taskReference
      type
      title
      message
      status
      fkLogId
      createdAt
    }
  }
`;

const formatMessage = message => {
  if (!message) {
    return message;
  }
  const l = 15;
  return `${`${message}`.substring(0, l)}${message.length >= l ? `...` : ``}`;
}

const formatTitle = title => {
  if (!title) {
    return title;
  }
  const l = 100;
  return `${`${title}`.substring(0, l)}${title.length >= l ? `...` : ``}`;
}

const formatDate = (date, onlyTime = false) => {
  if (!date || !moment(date).isValid()) {
    return date;
  }
  moment.locale("fr");
  return onlyTime ? moment(date).format("HH:mm:ss") : moment(date).format("DD/MM/YYYY HH:mm:ss");
}

const useStyles = makeStyles(theme => {
  return {
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.error.contrastText,
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    pending: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.success.contrastText,
    },
    badge: {
      backgroundColor: "#cccccc",
      color: theme.palette.primary.contrastText,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#cccccc",
      top: "50%",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    iconLine: {
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "5px",
      paddingBottom: "5px",
      width: "20px",
    },
    iconTitle: {
      paddingLeft: "25px",
      paddingRight: "25px",
      width: "30px",
    },
    ulRoot: {
      width: "100%",
      maxHeight: "200px",
      overflow: "auto",
    },
    ulRootFull: {
      width: "100%",
      height: "calc(100vh - 200px)",
      overflow: "auto",
    },
    sticky: {
      width: "100%",
    },
    logTitle: {
      padding: theme.spacing(2),
    },
    listItem: {
      padding: "0px",
      alignItems: "stretch"
    },
    listItemText: {
      margin: "0",
    },
    listItemPrimary: {
      fontSize: "0.8rem",
    },
    listItemSecondary: {
      fontSize: "0.7rem",
      display: "inline",
      marginLeft: theme.spacing(1),
    },
    mainTitle: {
      flexGrow: "1",
    },
    rootCaption: {
      userSelect: "all",
      color: "#9e9e9e",
    },
    rootOpenButton: {
      padding: "0px",
      marginLeft: "5px",
    }
  }
});

LogIcon.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

function LogIcon({ status, className }) {
  const classes = useStyles();
  return (
    <div className={className ? clsx(classes[status.toLowerCase()], className, classes.iconContainer) : clsx(classes[status.toLowerCase()], classes.iconContainer, classes.iconLine)}>
      {status === "SUCCESS" && <CheckIcon fontSize={"small"} />}
      {status === "WARNING" && <WarningIcon fontSize={"small"} />}
      {status === "ERROR" && <ErrorOutlineIcon fontSize={"small"} />}
      {status === "INFO" && <InfoIcon fontSize={"small"} />}
      {status === "PENDING" && <DirectionsRunIcon fontSize={"small"} />}
    </div>
  )
}

LogLine.propTypes = {
  log: PropTypes.object,
};

function LogLine({ log }) {
  const classes = useStyles();
  const { status, title, message, createdAt } = log;
  const [open, setOpen] = React.useState(false);
  function dynProps() {
    return message ? {
      onClick: () => setOpen(!open),
      button: true,
    } : {};
  }
  return (
    <>
      <ListItem classes={{ root: classes.listItem }} dense {...dynProps()}>
        <ListItemIcon>
          {
            message && <Badge variant="dot" classes={{ badge: classes.badge }}>
              <LogIcon status={status} />
            </Badge>
          }
          {
            !message && <LogIcon status={status} />
          }
        </ListItemIcon>
        <ListItemText
          classes={{ root: classes.listItemText, primary: classes.listItemPrimary, secondary: classes.listItemSecondary }}
          primary={`${formatTitle(title)} - ${formatDate(createdAt, true)}`}
          secondary={formatMessage(message)}
        />
      </ListItem>
      {message && <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem dense>
          <Typography classes={{ root: classes.listItemSecondary }}>{message}</Typography>
        </ListItem>
      </Collapse>}
    </>
  );
}

//SHOW AFTER CLICK ON DEPLOY VERSION WITH LOG PROPS => LISTEN TO SUBSCRIPTIONS CHANNEL ID
export default function Log({ log, standAlone, sticky }) {
  const classes = useStyles();
  const [masterLog, setMasterLog] = useState({});
  const [lineLogs, setLineLogs] = useState([]);
  useSubscription(SUBSCRIBE_LOG, {
    variables: {
      channelId: masterLog.id || "undefined"
    },
    onSubscriptionData: ({ subscriptionData: { data: { logAdded } } }) => {
      if (masterLog && masterLog.id) {
        setLineLogs([...lineLogs, logAdded])
      }
    }
  });

  const ulRootRef = useCallback(node => {
    if (node !== null) {
      node.scrollTop = node.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineLogs]);

  React.useEffect(() => {
    if (log && log.id) {
      setMasterLog(log);
    }
  }, [log]);

  return (
    <>
      <List disablePadding={true} classes={{ root: standAlone ? classes.ulRootFull : sticky ? classes.sticky : classes.ulRoot }} ref={ulRootRef}>
        {
          masterLog && masterLog.id
            ? <>
              <ListItem classes={{ root: classes.listItem }} dense>
                <ListItemIcon>
                  {
                    <LogIcon className={classes.iconTitle} status={masterLog.status} />
                  }
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "h5" }}
                  primary={<Grid
                    container
                    wrap={"nowrap"}
                    alignItems={"center"}
                  >
                    <Grid item className={classes.mainTitle}>
                      <Typography variant="h5">
                        {formatTitle(masterLog.title)} - {formatDate(masterLog.createdAt)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" classes={{ root: classes.rootCaption }}>
                        {standAlone && masterLog.id}
                        {
                          !standAlone && <IconButton
                            classes={{ root: classes.rootOpenButton }}
                            onClick={() => window.open(`/logs/${masterLog.id}`)}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  }
                />
              </ListItem>
              <Divider component="li" />
              {
                lineLogs.map(lineLog => {
                  return (
                    <>
                      <LogLine log={lineLog} />
                      <Divider component="li" />
                    </>
                  )
                })
              }
            </>
            : null
        }
      </List>
    </>
  );
}

Log.propTypes = {
  log: PropTypes.object,
  standAlone: PropTypes.bool,
};

