import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // width: 200
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

Filter.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default function Filter({ className, placeholder, value, onChange }) {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.root, className)}>
      <InputBase
        className={classes.input}
        placeholder={placeholder || "Filter"}
        inputProps={{ "aria-label": "Filter" }}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <IconButton className={classes.iconButton} aria-label="filter">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

Filter.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
