import React, { useContext, useEffect, useMemo } from "react";
import gql from "graphql-tag";
import { QuotasContext } from "../general/QuotasContext";
import {
  AWSQuotas,
  AWS_ACCOUNT_USAGE_INTERVAL_SECONDS,
  WARN_MAX_USAGE_PERCENT,
} from "../utils/constants";
import { useQuery } from "@apollo/react-hooks";
import { CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import theme from "../theme";

export const AWSSNSUsage = ({ profile }) => {
  const [quotasAreReached, setReached] = useContext(QuotasContext);

  const getAWSSNSStats = gql`
    query($profile: String) {
      AWSUsageStats(profile: $profile) {
        SNSStats {
          subscriptionsAmount
        }
      }
    }
  `;

  const {
    loading,
    error,
    data: {
      AWSUsageStats: { SNSStats: { subscriptionsAmount } = {} } = {},
    } = {},
  } = useQuery(getAWSSNSStats, {
    variables: { profile },
    pollInterval: AWS_ACCOUNT_USAGE_INTERVAL_SECONDS * 1000,
  });

  console.log({ subscriptionsAmount });

  const usageInPercent = useMemo(
    () => (subscriptionsAmount / AWSQuotas.snsSubscriptions.default) * 100,
    [subscriptionsAmount]
  );

  useEffect(() => {
    if (quotasAreReached) return;
    setReached(usageInPercent >= WARN_MAX_USAGE_PERCENT);
  }, [quotasAreReached, setReached, usageInPercent]);

  return (
    <div>
      {loading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {error && error.message}
      {!loading && !error && (
        <div>
          <LinearProgress
            variant="determinate"
            value={usageInPercent}
            color={
              usageInPercent < WARN_MAX_USAGE_PERCENT ? "primary" : "secondary"
            }
          />
          <span>{`Subscriptions : ${subscriptionsAmount} / `}</span>
          <span
            style={{ color: theme.palette.warning.main }}
          >{`${AWSQuotas.snsSubscriptions.default}`}</span>
        </div>
      )}
    </div>
  );
};
