import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";

export const exportToXlsx = (e, options) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Main");
  const component = e.component;
  const filename = options.title ? `${options.title}.xlsx` : "Datagrid";

  return exportDataGrid({
    component,
    worksheet,
    ...options
  }).then(() =>
    workbook.xlsx.writeBuffer().then(buffer =>
      saveAs(
        new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }),
        filename
      )
    )
  );
};
