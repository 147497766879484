import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  AWS_ACCOUNT_USAGE_INTERVAL_SECONDS,
  WARN_MAX_USAGE_PERCENT
} from "../utils/constants";
import { CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import { bytesToGigabytes } from "../utils/convertNumbers";
import { QuotasContext } from "../general/QuotasContext";

export const AWSAccountUsageWidget = ({ profile }) => {
  const [quotasAreReached, setReached] = useContext(QuotasContext);

  const getAWSUsageStats = gql`
    query($profile: String) {
      AWSUsageStats(profile: $profile) {
        AccountUsage {
          totalCodeSize
          totalUsedCodeSize
          functionCount
        }
        BucketsStats {
          amount
        }
      }
    }
  `;
  const { loading, error, data: stats } = useQuery(getAWSUsageStats, {
    variables: { profile },
    pollInterval: AWS_ACCOUNT_USAGE_INTERVAL_SECONDS * 1000
  });

  const {
    AWSUsageStats: {
      AccountUsage: { totalUsedCodeSize, totalCodeSize, functionCount } = {}
    } = {}
  } = stats || {};

  const displayInGb = bytes => {
    const inGb = bytesToGigabytes(bytes);
    return `${Math.round(inGb * 100) / 100}`;
  };

  const usedInPercent = (used, available) => {
    return (used / available) * 100;
  };

  useEffect(() => {
    if (quotasAreReached) return;
    setReached(
      usedInPercent(totalUsedCodeSize, totalCodeSize) >= WARN_MAX_USAGE_PERCENT
    );
  }, [setReached, totalUsedCodeSize, totalCodeSize, quotasAreReached]);

  return (
    <div>
      {loading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {error && error.message}
      {!loading && !error && (
        <div>
          <p>{`Lambdas : ${functionCount}`}</p>
          <LinearProgress
            variant="determinate"
            value={usedInPercent(totalUsedCodeSize, totalCodeSize)}
            color={
              usedInPercent(totalUsedCodeSize, totalCodeSize) <
              WARN_MAX_USAGE_PERCENT
                ? "primary"
                : "secondary"
            }
          />
          <p>{`${displayInGb(totalUsedCodeSize)} / ${displayInGb(
            totalCodeSize
          )} Gb`}</p>
        </div>
      )}
    </div>
  );
};
