import React, { createContext } from "react";
import PropTypes from "prop-types";

export const UserContext = createContext();
export const suKey = "SU";

export default function UserProvider({ children }) {
    const [user] = React.useState({
        su: localStorage.getItem(suKey) === "true"
    });
    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
}

UserProvider.propTypes = {
    children: PropTypes.any,
    userId: PropTypes.string.isRequired
};
