import React from "react";
import {
    Button,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    button: {
        display: "flex",
        flexWrap: "no-wrap"
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    spinner: {
        marginRight: theme.spacing(1),
    }
}));

ProgressButton.propTypes = {
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    busy: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default function ProgressButton({ className, action, busy = false, label, disabled }) {
    const classes = useStyles();
    return (
        <div className={classes.buttonsContainer}>
            <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={async () => {
                    if (!busy) {
                        action()
                    }
                }}
                className={className || classes.button}
            >
                {
                    busy === true
                        ? <CircularProgress
                            className={classes.spinner}
                            size={20}
                            color={"secondary"}
                        />
                        : null
                }
                {label}
            </Button>
        </div>
    );
}
