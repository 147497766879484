import React from "react";

import { Grid, makeStyles, Paper } from "@material-ui/core";
import dayjs from "dayjs";
import * as duration from "dayjs/plugin/duration";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Export,
  FilterRow,
  Grouping,
  SearchPanel
} from "devextreme-react/data-grid";
import { exportToXlsx } from "./helper";

const useStyles = makeStyles({
  paper: {
    marginBottom: "20px",
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
});

dayjs.extend(duration);

export const AirworkUsersDetails = ({
  matomoStat,
  dicUsersById,
  selectedModule
}) => {
  const classes = useStyles();
  const statsWithUniqueDetails = Object.values(
    matomoStat.reduce((acc, value) => {
      if (!acc[value.idvisit]) acc[value.idvisit] = value;
      return acc;
    }, {})
  );

  const extendedStats = statsWithUniqueDetails?.map(stat => {
    return {
      ...stat,
      trigram: dicUsersById[stat.user_Id]?.trigram,
      email: dicUsersById[stat.user_Id]?.email
    };
  });

  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid item className={classes.item}>
        <h2>Détails de navigation</h2>
        <p>Pour tous les utilisateurs, toutes versions confondues</p>
      </Grid>
      <Grid item>
        <DataGrid
          dataSource={extendedStats}
          rowKey="id"
          allowColumnResizing
          allowColumnReordering
          showBorders
          rowAlternationEnabled
          columnAutoWidth
          onExporting={e =>
            exportToXlsx(e, {
              title: `stats-${selectedModule}-details-users`,
              dicUsersById
            })
          }
        >
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={400} />
          <Grouping autoExpandAll={false} />
          <Export enabled={true} formats={"xls"} />
          <Column dataField="trigram" caption="Trigram" width="auto" />
          <Column
            dataField="user_Id"
            caption="user Id"
            width="240"
            groupIndex={0}
            showWhenGrouped={true}
          />
          <Column
            dataField="custom_dimension_2"
            caption="client"
            width="auto"
          />
          <Column
            dataField="custom_dimension_1"
            caption="version"
            width="auto"
          />
          <Column
            dataField="visit_first_action_time"
            caption="date"
            calculateCellValue={rowData =>
              dayjs(rowData.visit_first_action_time).format(
                "DD-MM-YYYY, HH:mm:ss"
              )
            }
          />
          <Column
            dataField="visit_total_time"
            caption="duration"
            calculateCellValue={rowData =>
              dayjs(rowData.visit_total_time).format("HH:mm:ss")
            }
          />
          <Column dataField="name" caption="page" width="auto" />
        </DataGrid>
      </Grid>
    </Paper>
  );
};
