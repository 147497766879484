import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Switch,
  FormControlLabel,
  CircularProgress,
  IconButton,
  Container,
  Tooltip,
} from "@material-ui/core";
import Warning from "../../utils/Warning";
import Error from "../../utils/Error";
import { flatten, orderBy } from "lodash";
import ProgressButton from "../../utils/ProgressButton";
import PropTypes from "prop-types";
import SaveIcon from '@material-ui/icons/Save';
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Filter from "../../utils/Filter";
import clsx from "clsx";

export const GET_CUTOMERS_MODULES_RIGHTS = gql`
  query ($stage: String!, $search: String) {
    AWACustomersModulesRightsByStage(stage: $stage, search: $search) {
      customers {
        rights {
          module_id
          access
          env
          customer_id
          name
          url
        }
        customer_id
        stage
        url
        name
        trigram
        isActive
        createdAt
        updatedAt
      }
      stage
      service {
        url
      }
    }
  }
`;

const GET_AWA_CLIENT_MODULES = gql`
  {
    AWAClientModules {
      name
    }
  }
`;

const UPDATE_CUSTOMER_MODULES_RIGHTS = gql`
  mutation updateCustomerModuleRight($rights: [AwaCustomerModuleRightInput]! ) {
    updateCustomerModuleRight(rights: $rights)
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  container: {
    display: "block",
    margin: 15
  },
  select: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.error.contrastText,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.success.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  thumb: {
    "&:hover": {
      backgroundColor: "#FFF"
    }
  },
  checked: {},
  track: {},
  search: {
    width: "100%"
  },
  sizeSmall: {
    padding: "0px"
  },
  moduleRoot: {
    // transform: "rotate(315deg)",
    fontSize: "10px",
  },
  error: {
    padding: theme.spacing(2),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginBottom: theme.spacing(2)
  },
  changed: {
    background: "#ffccbc",
  },
  saveIcon: {
    marginLeft: theme.spacing(1)
  },
  saveIconButton: {
    padding: "0px",
  },
  saveCircularButton: {
    width: "20px !important",
    height: "20px !important",
  },
  formControlSwitch: {
    width: "100%",
    margin: "0px",
    justifyContent: "center"
  },
  title: {
    flexGrow: "1"
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: theme.spacing(3)
  },
  stickyCellDefault: {
    position: "sticky",
    backgroundColor: "#ffffff",
  },
  stickyCellY: {
    position: "sticky",
    backgroundColor: "#ffffff",
    top: "0",
    zIndex: "99"
  },
  stickyCellXY: {
    position: "sticky",
    backgroundColor: "#ffffff",
    top: "0",
    zIndex: "101 !important",
  },
  secondStickyCell: {
    left: "82px",
    zIndex: "100"
  },
  firstStickyCell: {
    left: "0px",
    zIndex: "100"
  },
  warningHeader: {
    flexWrap: "nowrap",
    cursor: "pointer"
  },
  warningConfig: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(2),
  },
  tabContainer: {
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "100%",
    height: "60vh",
    overflowX: "scroll",
    overflowY: "scroll"
  }
}));

ModulesRights.propTypes = {
};

export default function ModulesRights() {
  const classes = useStyles();
  const [filteredModulesRightsByStage, setFilteredModulesRightsByStage] = React.useState([]);
  const [modules, setModules] = React.useState([]);
  const [initModulesRights, setInitModulesRights] = React.useState({});
  const [changedModulesRights, setChangedModulesRights] = React.useState({});
  const [filter, setFilter] = React.useState("");
  const [refetching, setRefetching] = React.useState(false);
  const [showADS, setShowADS] = React.useState(false);
  const [processHandle, setProcessHandle] = React.useState(true);
  const [variables, setVariables] = React.useState({
    stage: filter
  });

  const awaClientModules = useQuery(GET_AWA_CLIENT_MODULES, {
    fetchPolicy: "cache-first",
  });

  const { loading, error, data } = useQuery(GET_CUTOMERS_MODULES_RIGHTS, {
    variables,
    fetchPolicy: "cache-and-network",
    skip: !variables.stage || variables.stage.length < 1
  });
  const [updateCustomerModuleRight, updateCustomerModuleRightStatus] = useMutation(UPDATE_CUSTOMER_MODULES_RIGHTS, {
    refetchQueries: [
      {
        query: GET_CUTOMERS_MODULES_RIGHTS,
        variables
      }
    ],
    // onCompleted: ( data ) => {
    // }
  });

  React.useEffect(() => {
    const hasAp = ['AIRWORK', 'ELOGBOOK', 'EQUAL', 'MTV', 'CORE', 'WORKSHOP', 'FINANCIAL', 'FLIGHTOPS', 'COSTMONITORING']
    if (awaClientModules && awaClientModules.data && awaClientModules.data.AWAClientModules) {
      setModules(awaClientModules.data.AWAClientModules.map(module => ({ name: module.name, hasAp: hasAp.includes(module.name) })).sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awaClientModules.data]);

  React.useEffect(() => {
    if (data && data.AWACustomersModulesRightsByStage) {
      const allModuleRights = data.AWACustomersModulesRightsByStage.reduce((accS, byStage) => ({
        ...accS,
        ...byStage.customers.reduce((accC, customer) => ({
          ...accC,
          ...customer.rights.reduce((accR, right) => ({
            ...accR,
            [formId(right)]: right
          }), {})
        }), {})
      }), {});
      setInitModulesRights(allModuleRights);
      setChangedModulesRights({});
      setRefetching(false);
      filterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, showADS]);

  function filterData() {
    if (data && data.AWACustomersModulesRightsByStage) {
      const allCustomersByStage = orderBy(flatten((data.AWACustomersModulesRightsByStage || []).map(byStage => byStage.customers.filter(customer => showADS ? true : !(customer.trigram === "ADS")))), "trigram");
      const filteredFilter = allCustomersByStage.filter(customer => customer.stage.toLowerCase().includes(filter.toLowerCase()) || customer.trigram.toLowerCase().includes(filter.toLowerCase()))
      setFilteredModulesRightsByStage(filteredFilter);
    }
  }

  function formId(right) {
    return `${right.customer_id}__${right.env}__${right.module_id}`
  }

  return (
    <Container
      maxWidth="lg"
      onKeyPress={
        (e) => e && e.which === 13 ? setVariables({
          stage: filter,
        }) : null
      }
    >
      {updateCustomerModuleRightStatus && updateCustomerModuleRightStatus.error && <Paper className={classes.error}>{updateCustomerModuleRightStatus.error.message}</Paper>}
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={3}
        >
          <Grid item container>
            <Typography component="h1" variant="h4" className={classes.title}>
              Manage customer's modules rights
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showADS}
                  onChange={e => {
                    setShowADS(!showADS);
                  }}
                  value={`showADS`}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={`Show ADS customer`}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={processHandle}
                  onChange={e => {
                    setProcessHandle(!processHandle);
                  }}
                  value={`processHandle`}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={`Process action on module activation`}
            />
            <Filter
              name={`filter-stage`}
              className={classes.search}
              placeholder={"Stage à rechercher ex: PROD--14-11, ADMIN--14-8, BETATESTELB, DEV..."}
              value={filter}
              onChange={e => setFilter(e)}
            />
            <Grid item xl={12} className={classes.buttons}>
              <ProgressButton
                label={"Search"}
                action={() => setVariables({
                  stage: filter,
                })
                }
                busy={loading}
              />
            </Grid>
          </Grid>
          <Error
            className={classes.warningConfig}
            showIcon={true}
            messages={
              <Typography classes={{ root: classes.moduleRoot }}>DON'T FORGET TO ACTIVATE/DEACTIVATE CORRESPONDING SERVICE IN AIRPACK CONFIG FILE FOR MODULES MARKED WITH A *</Typography>
            }
          />
          <Grid item container className={classes.tabContainer}>
            {
              (awaClientModules.loading) && <Grid
                container
                justify="center"
              >
                <Grid item>
                  <CircularProgress data-testid="loading" />
                </Grid>
              </Grid>
            }
            {
              (!awaClientModules.loading && !awaClientModules.error) && <Table size="small">
                <TableHead>
                  <TableRow style={{ zIndex: "50" }}>
                    <TableCell key={`th-trigramn`} align={"center"} className={clsx(classes.firstStickyCell, classes.stickyCellXY)}>Trigram</TableCell>
                    <TableCell key={`th-stage`} align={"center"} className={clsx(classes.secondStickyCell, classes.stickyCellXY)}>Stage</TableCell>
                    {
                      modules.map(({ name, hasAp }) => <TableCell key={`th-module-${name}`} align={"center"} className={classes.stickyCellY}>
                        {
                          name === "AIRWORK" && processHandle ?
                            <Tooltip title="Enabling this module will enable 'MRO - general' in Airpack ( it needs a started deployment to work )">
                              <div>
                                <Warning
                                  className={classes.warningHeader}
                                  showIcon={true}
                                  messages={
                                    <Typography classes={{ root: classes.moduleRoot }}>{name}{hasAp && "*"}</Typography>
                                  }
                                />
                              </div>
                            </Tooltip>
                            : <Typography classes={{ root: classes.moduleRoot }}>{name}{hasAp && "*"}</Typography>
                        }
                      </TableCell>)
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading && !error && !!modules.length && filteredModulesRightsByStage && filteredModulesRightsByStage.map(({ customer_id, trigram, rights, stage }) => (
                    <TableRow key={`row-${customer_id}-${stage}`}>
                      <SaveCell
                        customerId={customer_id}
                        stage={stage}
                        trigram={trigram}
                        refetching={refetching}
                        setRefetching={setRefetching}
                        updateStatus={updateCustomerModuleRightStatus}
                        changedRights={changedModulesRights}
                        updateRight={updateCustomerModuleRight} />
                      <TableCell key={`cell-${customer_id}-${stage}-stage`} align={"center"} className={clsx(classes.stickyCellDefault, classes.secondStickyCell, classes.sizeSmall)}>{stage}</TableCell>
                      {
                        modules.map(({ name }) => <RightCell
                          key={`cell-${customer_id}-${stage}-${name}`}
                          rights={rights}
                          module={name}
                          formId={formId}
                          trigram={trigram}
                          updateRight={updateCustomerModuleRight}
                          changeRight={setChangedModulesRights}
                          initRights={initModulesRights}
                          changedRights={changedModulesRights}
                          processHandle={processHandle}
                        />)
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

const SaveCell = React.memo(({ trigram, customerId, stage, changedRights, updateRight, updateStatus, setRefetching, refetching }) => {
  const classes = useStyles();
  const id = `${customerId}__${stage}`;
  const regex = new RegExp(`^${id}__`, "i");
  const rowChanged = Object.keys(changedRights).filter(right => regex.test(right));
  const busy = (updateStatus && updateStatus.loading === true) || (refetching === true);
  return (<TableCell key={`cell-${id}-trigram`} align={"center"} className={clsx(classes.stickyCellDefault, classes.firstStickyCell, classes.sizeSmall)}>
    <Grid
      container
      justify="center"
      alignItems="center"
    >
      <Grid item>
        {
          trigram
        }
      </Grid>
      <Grid item className={classes.saveIcon}>
        {
          rowChanged && rowChanged.length ? <IconButton
            classes={{ root: classes.saveIconButton }}
            onClick={() => {
              if (!busy) {
                setRefetching(true);
                updateRight({ variables: { rights: Object.entries(changedRights).map(([id, value]) => value) } });
              }
            }}
          >
            {
              busy ? <CircularProgress classes={{ root: classes.saveCircularButton }} /> : <SaveIcon />
            }
          </IconButton> : null
        }
      </Grid>
    </Grid>
  </TableCell>
  );
}, (oldProps, newProps) => {
  return oldProps.changedRights === newProps.changedRights && oldProps.updateStatus === newProps.updateStatus
});
SaveCell.propTypes = {
  trigram: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
  changedRights: PropTypes.object,
  updateRight: PropTypes.func.isRequired,
  updateStatus: PropTypes.object,
  setRefetching: PropTypes.func,
  refetching: PropTypes.bool,
};


const RightCell = React.memo(({ rights, module, formId, initRights, changedRights, changeRight, trigram, processHandle }) => {
  const classes = useStyles();
  const findRight = rights.find(r => r.name.toLowerCase() === module.toLowerCase());
  if (!findRight) {
    return <TableCell align={"center"} classes={{ sizeSmall: classes.sizeSmall }}></TableCell>
  }
  const id = formId(findRight);
  const changed = Object.keys(changedRights).includes(id);
  const checked = initRights[id].access;
  const access = changed ? !checked : checked;
  return (<TableCell align={"center"} classes={{ root: changed && classes.changed, sizeSmall: classes.sizeSmall }}>
    <FormControlLabel
      classes={{ root: classes.formControlSwitch }}
      control={
        <Switch
          classes={{
            switchBase: classes.switchBase,
            track: classes.track,
            checked: classes.checked,
            thumb: classes.thumb,
          }}
          disableRipple
          checked={access}
          onChange={() => {
            const input = {
              name: module,
              access: !access,
              customer: trigram,
              stage: findRight.env,
              customer_id: findRight.customer_id,
              module_id: findRight.module_id,
              url: findRight.url,
              processHandle
            };
            const newRights = Object.assign({}, changedRights);
            if (changed) {
              delete newRights[id];
            } else {
              newRights[id] = input;
            }
            changeRight(newRights);
          }}
          value={`${id}`}
        />
      }
    />
  </TableCell>
  );
}, (oldProps, newProps) => {
  return oldProps.changedRights === newProps.changedRights && oldProps.processHandle === newProps.processHandle
});

RightCell.propTypes = {
  rights: PropTypes.array.isRequired,
  module: PropTypes.string.isRequired,
  formId: PropTypes.func.isRequired,
  initRights: PropTypes.object,
  changedRights: PropTypes.object,
  changeRight: PropTypes.func,
  trigram: PropTypes.string.isRequired,
  processHandle: PropTypes.bool,
};