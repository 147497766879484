import React from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Stepper,
  StepLabel,
  Step,
  Button,
  Container
} from "@material-ui/core";
import General from "./General";
import Finalize from "./Finalize";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import Yup, { REQUIRED_FIELD_MESSAGE, INVALID_SEMVER } from "../../../utils/CustomYup";
import ModuleVersions from "./ModuleVersions";
import { GET_AWA_VERSIONS } from "../AWAVersions";
import { navigate } from "@reach/router"

const versionSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE).isValidSemVer(INVALID_SEMVER)
});

const CREATE_VERSION = gql`
  mutation createAWAVersion($version: AWACreateInput!) {
    createAWAVersion(version: $version) {
      id
      name
    }
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  error: {
    padding: theme.spacing(2),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginBottom: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["General", "Modules", "Finalize"];

const initialValues = {
  name: "",
  description: "",
  modules: {},
  dicModules: {},
  fromVersion: "LATEST"
};

function formDataToMutation(version) {
  return {
    name: version.name,
    description: version.description,
    moduleVersions: Object.keys(version.modules).map(id => ({
      moduleId: parseInt(id, 10),
      tagName: version.modules[id]
    }))
  };
}

export default function NewVersion() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [createVersionMutation, { error }] = useMutation(CREATE_VERSION, {
    refetchQueries: [
      {
        query: GET_AWA_VERSIONS,
        variables: {
          awaitRefetchQueries: true
        }
      }
    ],
    onCompleted: (data) => {
      navigate(`/awa/versions/${data.createAWAVersion.id}`);
    }
  });

  const createVersion = async version => {
    await createVersionMutation({
      variables: { version: formDataToMutation(version) }
    });
  };

  const handleNext = async ({ validateForm, setStatus }) => {
    const errors = await validateForm();
    if (Object.entries(errors).length === 0) {
      setActiveStep(activeStep + 1);
    } else {
      setStatus({ errors });
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step, formik) {
    switch (step) {
      case 0:
        return <General formik={formik} />;
      case 1:
        return <ModuleVersions formik={formik} />;
      case 2:
        return <Finalize formik={formik} />;
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <Container maxWidth="lg">
      {error && <Paper className={classes.error}>{error.message}</Paper>}
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" className={classes.title}>
          New AWA version
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={versionSchema}
            >
              {formik => (
                <form onSubmit={formik.handleSubmit}>
                  {getStepContent(activeStep, formik)}
                  {/* {error && error.message} */}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      // type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        activeStep === steps.length - 1
                          ? createVersion(formik.values)
                          : handleNext(formik)
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Create version"
                        : "Next"}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </>
          {/* )} */}
        </>
      </Paper>
    </Container>
  );
}
