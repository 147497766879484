import React from "react";
import PropTypes from "prop-types";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BaseIconTextButton from '../BaseIconTextButton';
import { navigate } from "@reach/router";
export default function DeployBetaVersionButton({ versionId, disabled }) {
  return (
    <BaseIconTextButton
      label={"Beta"}
      tooltip={"Create / Update beta"}
      disabled={disabled}
      icon={<AddCircleIcon />}
      onClick={() => navigate(`/awa/versions/deploy/${versionId}/BETA`)}
    />
  );
}

DeployBetaVersionButton.propTypes = {
  disabled: PropTypes.any,
  versionId: PropTypes.string.isRequired
};