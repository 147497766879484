import { Grid, makeStyles, Paper } from "@material-ui/core";
import { DataGrid } from "devextreme-react";
import { Column, Export, Grouping } from "devextreme-react/data-grid";
import { groupBy, prop } from "ramda";
import React from "react";
import { exportToXlsx } from "./helper";

const regexpRoot = new RegExp(/^ROO/i);
const regexpProd = new RegExp(/^PROD/i);

const useStyles = makeStyles({
  table: {
    margin: "20px"
  },
  item: {
    marginTop: "20px"
  },
  paper: {
    marginBottom: "20px",
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
});

export const AirworkUsersSummary = ({
  uniqueUsers,
  dicUsersById,
  selectedModule
}) => {
  const classes = useStyles();
  const extendUsers = uniqueUsers
    ?.map(user => {
      return {
        ...user,
        trigram: dicUsersById[user.user_Id]?.trigram,
        email: dicUsersById[user.user_Id]?.email,
        deletedAt: dicUsersById[user.user_Id]?.deletedAt
      };
    })
    .filter(user => !regexpRoot.test(user.trigram));

  const dicUsersByCustomer = groupBy(prop("custom_dimension_2"), extendUsers);
  const customerName = Object.keys(dicUsersByCustomer);

  const getUsersNbInProd = name =>
    dicUsersByCustomer[name].filter(user =>
      regexpProd.test(user.custom_dimension_1)
    ).length;

  return (
    <Grid container>
      <Paper className={classes.paper} elevation={3} xs={8}>
        <Grid item xs={12}>
          <h2>
            Utilisateurs actifs sur le module {selectedModule.toUpperCase()}
          </h2>
          <p>
            Utilisateurs non ROOT ayant utilisés le module{" "}
            <b>{selectedModule.toUpperCase()}</b> sur une version de PROD
            uniquement. <br />
            (les BETAS ne sont pas comptabilisées)
          </p>
        </Grid>
        <Grid item style={{ marginBottom: "20px" }} xs={12}>
          {customerName.map(name => {
            return (
              <li>
                <b>{name}</b> - Utilisateurs en PROD :{" "}
                <b>{getUsersNbInProd(name)}</b>
              </li>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <h2>Liste des utilisateurs par client</h2>
          <p>utilisateurs non ROOT, toutes versions confondues</p>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            dataSource={extendUsers}
            allowColumnResizing
            allowColumnReordering
            showBorders
            rowAlternationEnabled
            columnAutoWidth
            onExporting={e =>
              exportToXlsx(e, { title: `stats-${selectedModule}-users` })
            }
          >
            <Export enabled={true} formats={"xls"} />
            <Grouping autoExpandAll={false} />
            <Column dataField="user_Id" caption="userId" />
            <Column
              dataField="custom_dimension_1"
              caption="Version"
              width="auto"
              groupIndex={1}
            />
            <Column
              dataField="trigram"
              caption="Trigram"
              defaultSortIndex={1}
              width="auto"
            />
            <Column dataField="email" caption="Email" />
            <Column dataField="deletedAt" caption="Deleted at" width="auto" />
            <Column
              dataField="custom_dimension_2"
              caption="Customer"
              groupIndex={0}
            />
          </DataGrid>
        </Grid>
      </Paper>
    </Grid>
  );
};
