import React from "react";
import moment from "moment";
import Success from "../../../utils/Success";
import PropTypes from "prop-types";

LastDeploy.propTypes = {
    deployedAt: PropTypes.string,
    stage: PropTypes.string,
    show: PropTypes.bool,
};

export default function LastDeploy({ show = true, deployedAt, stage }) {
    return (<>
        {show && deployedAt && <Success showIcon={true} messages={`Last sucessfull deploy on ${stage.toUpperCase()} done at ${moment(deployedAt).format("DD-MM-YYYY HH:mm")}`} />}
    </>)
}
