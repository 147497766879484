import { useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag";
const ADD_LOG = gql`
  mutation addLog($log: LogInput) {
    addLog(log: $log){
      id
      taskReference
      type
      title
      message
      status
      fkLogId
      createdAt
    }
  }
`;
export default () => useMutation(ADD_LOG);