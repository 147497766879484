import React, { useState } from "react";
import {
    CircularProgress,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    TablePagination,
    Grid,
    makeStyles,
    Dialog,
    Switch,
    FormControlLabel,
    IconButton,
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { useToggle } from "react-use";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import gql from "graphql-tag";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useQuery } from "@apollo/react-hooks";
// import { orderBy } from "lodash";
import Title from "../../Title";
import Filter from "../../utils/Filter";
import moment from "moment";
import PropTypes from "prop-types";

export const GET_HISTORY_VERSIONS = gql`
  query ($awaVersionId: String, $fromDate: DateTime){
    AWAHistoryVersions (awaVersionId: $awaVersionId, fromDate: $fromDate){
        id
        awaVersionId
        type
        description
        createdAt
    }
  }
`;

const useStyles = makeStyles(theme => ({
    table: {
        width: "100%",
    },
    footer: {
        marginTop: theme.spacing(3),
    },
    cellBreakAll: {
        wordBreak: "break-all",
    },
    sizeSmall: {
        padding: 0,
        paddingLeft: "5px",
    }
}));

export default function AWAHistoryVersions() {
    // const classes = useStyles();
    const [filter, setFilter] = useState("");
    return (
        <>
            <Title>History</Title>
            <Grid
                container
                direction="column"
                spacing={3}
            >
                <Grid item>
                    <AWAHistoryVersion filter={filter} />
                </Grid>
                <Grid
                    container
                    item
                    wrap={"nowrap"}
                    justify={"flex-end"}
                    alignItems={"center"}
                >
                    <Grid item>
                        <Filter
                            placeholder={"Recherche"}
                            value={filter}
                            onChange={e => setFilter(e)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

ModalDescription.propTypes = {
    description: PropTypes.string,
};
function ModalDescription({ description }) {
    const classes = useStyles();
    const formatedDescription = description.split("\\n").map(desc => <div>{desc}</div>);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <div style={{ padding: "20px", whiteSpace: "pre-line" }}>
                    {
                        formatedDescription
                    }
                </div>
            </Dialog>
            {
                description.length > 100
                    ? <>
                        {
                            description.substring(0, 20)
                        }
                        <IconButton className={classes.sizeSmall} size={"small"}>
                            <MoreHorizIcon fontSize={"small"} onClick={handleClickOpen} />
                        </IconButton>
                    </>
                    : description
            }
        </>
    )
}

AWAHistoryVersion.propTypes = {
    id: PropTypes.string,
};
export function AWAHistoryVersion({ id, filter = "" }) {

    const classes = useStyles();
    const [now, setNow] = useState(moment().subtract(14, 'days').startOf('day'));
    const { loading, error, data } = useQuery(GET_HISTORY_VERSIONS, {
        variables: { awaVersionId: id, fromDate: now }
    })
    const [page, setPage] = useState(0);
    const [hideErrors, toggleHideErrors] = useToggle(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const orderedAndFilteredHistoryVersions = data && data.AWAHistoryVersions
        ? data.AWAHistoryVersions.filter(history => new RegExp(`${filter}`, "gi").test(JSON.stringify(history)))
            .filter(history => hideErrors ? !history.type.includes("error") : true)
            .map(history => ({
                ...history,
                createdAt: moment(history.createdAt).format("DD/MM/YYYY HH:mm"),
            }))
        : [];

    return (
        <>
            {loading && <Grid
                container
                justify="center"
            >
                <Grid item>
                    <CircularProgress data-testid="loading" />
                </Grid>
            </Grid>}
            {!loading && !error && (
                <>
                    <Grid className={classes.table}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>AWA</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderedAndFilteredHistoryVersions.slice(page * 5, page * 5 + 5)
                                    .map(({ id, awaVersionId, type, description, createdAt }) => (
                                        <TableRow key={id}>
                                            <TableCell>{awaVersionId}</TableCell>
                                            <TableCell>{createdAt}</TableCell>
                                            <TableCell className={classes.cellBreakAll}>{type}</TableCell>
                                            <TableCell className={classes.cellBreakAll}>
                                                <ModalDescription description={description} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid
                        container
                        justify={"flex-end"}
                        className={classes.footer}
                    >
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        id="hideErrors"
                                        name="hideErrors"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        checked={hideErrors}
                                        onChange={() => toggleHideErrors()}
                                        value={hideErrors}
                                    />
                                }
                                label={`Hide errors`}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="date-picker-inline"
                                    label="From"
                                    value={now}
                                    onChange={e => setNow(e)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={5}
                                component="div"
                                count={orderedAndFilteredHistoryVersions.length}
                                rowsPerPage={5}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={handleChangePage}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}