import React, { useContext, useState } from "react";
import {
    Tooltip,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import RepeatIcon from '@material-ui/icons/Repeat';
import { LoggedContext } from '../LoggedContext';
import { useMutation } from "@apollo/react-hooks";
import { navigate } from "@reach/router";
import gql from "graphql-tag";
const RESTART = gql`
  mutation restart {
    restart
  }
`;

export default function OtherButton() {
    const { logged } = useContext(LoggedContext);
    return (
        <>
            {
                logged &&
                <RestartButton />
            }
        </>
    );
}

function RestartButton() {
    const [loadingRestart, setLoadingRestart] = useState(false);
    const [restart] = useMutation(RESTART);
    return (
        <Tooltip
            title={`Restart`}
        >
            <IconButton color="inherit"
                data-testid="restartButton"
                onClick={async () => {
                    if (loadingRestart) {
                        return
                    }
                    setLoadingRestart(true)
                    restart();
                    setTimeout(() => {
                        setLoadingRestart(false)
                        navigate("/", { replace: true });
                    }, 30000);
                }}
            >
                {
                    loadingRestart
                        ? <CircularProgress
                            size={20}
                            color={"secondary"}
                        />
                        : <RepeatIcon />
                }
            </IconButton>
        </Tooltip>
    );
}