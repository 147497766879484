import React, { useState } from "react";
import Title from "../Title";
import {
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  Grid,
  TablePagination,
  makeStyles,
} from "@material-ui/core";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { orderBy } from "lodash";
import DeleteBetaButton from "../awa/DeleteBetaButton"
import AddUpdateCustomerVersionButton from "../awa/versions/AddUpdateCustomerVersionButton";
import Filter from "../utils/Filter";

export const GET_CUSTOMER_VERSIONS = gql`
  query {
    AWACustomerVersions {
      id
      trigram
      airpackVersion
      awaVersion
      stage
    }
  }
`;

const useStyles = makeStyles(theme => ({
  select: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));

const stages = ["ALL", "PROD", "BETA", "ADMIN", "TRAINING", "DEV"];

export default function Customers() {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_CUSTOMER_VERSIONS);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const rowsPerPage = 10;
  const [stageFiltered, setStageFiltered] = useState("PROD");
  const orderedFilteredCustomers = orderBy(data ? data.AWACustomerVersions : [], "trigram")
    .filter(customer => JSON.stringify(customer).toLowerCase().includes(filter.toLowerCase()))
    .filter(customer => stageFiltered === stages[0] || customer.stage.toUpperCase().startsWith(stageFiltered.toUpperCase()));

  return (
    <>
      <Title>Customers</Title>
      {loading && <Grid
        container
        justify="center"
      >
        <Grid item>
          <CircularProgress data-testid="loading" />
        </Grid>
      </Grid>}
      {!loading && !error && (
        <Grid
          container
          direction="column"
          spacing={3}
        >
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>AIRPACK</TableCell>
                  <TableCell>AWA</TableCell>
                  <TableCell>Stage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedFilteredCustomers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({ id, trigram, airpackVersion, awaVersion, stage }) => (
                    <TableRow key={id}>
                      <TableCell>{trigram}</TableCell>
                      <TableCell>{airpackVersion}</TableCell>
                      <TableCell>{awaVersion}</TableCell>
                      <TableCell>{stage}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={orderedFilteredCustomers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={handleChangePage}
            />
          </Grid>
          <Grid
            container
            item
            wrap={"nowrap"}
            justify={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <DeleteBetaButton />
            </Grid>
            <Grid item>
              <AddUpdateCustomerVersionButton />
            </Grid>
            <Grid item>
              <Select
                name={`select-stage`}
                value={stageFiltered}
                onChange={e => setStageFiltered(e.target.value)}
                className={classes.select}
              >
                {stages.map(stage => (
                  <MenuItem key={stage} value={stage}>
                    {stage}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Filter
                placeholder={"Recherche"}
                value={filter}
                onChange={e => setFilter(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
