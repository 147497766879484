import React from "react";
import { TextField, Grid, Typography } from "@material-ui/core";
import Errors from "../../../utils/Errors"

export default function General({ formik }) {
  const {
    values: deployment,
    errors,
    touched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        General informations
      </Typography>
      <Grid container spacing={3}>
        <Grid item xl={12}>
          <TextField
            required
            id="name"
            name="name"
            label="Name"
            fullWidth
            autoComplete="deploymentName"
            value={deployment.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={(touched.name && Boolean(errors.name)) || Boolean(errors.name)}
          />
          <Errors messages={errors.name} />
        </Grid>
        <Grid item xl={12}>
          <TextField
            id="description"
            name="description"
            label="Description"
            fullWidth
            multiline
            rows={3}
            autoComplete="deploymentDescription"
            value={deployment.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Errors messages={errors.description} />
        </Grid>
      </Grid>
    </>
  );
}
