import React from "react";
import {
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    headerPanel: {
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)"
    },
    detailsColumn: {
        flexDirection: "column"
    }
}));

BaseExpansionPanel.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.any,
    summary: PropTypes.func,
    direction: PropTypes.string
};

export default function BaseExpansionPanel({ children, type, summary, direction, defaultExpanded = true }) {
    const classes = useStyles();
    return (<ExpansionPanel
        defaultExpanded={defaultExpanded}
    >
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${type}-content`}
            id={`panel-${type}-header`}
            className={classes.headerPanel}
        >
            {
                !summary && <Typography className={classes.heading}>{type}</Typography>
            }
            {
                summary && summary()
            }
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
            className={direction === "column" ? clsx(classes.headerPanel, classes.detailsColumn) : classes.headerPanel}>
            {
                children
            }
        </ExpansionPanelDetails>
    </ExpansionPanel>)
}