import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip, IconButton } from "@material-ui/core";
import { navigate } from "@reach/router";

export default function AddUpdateCustomerVersionButton() {
  return (
    <Tooltip title="Add/Update/Delete a customer version">
      <IconButton
        onClick={() => navigate("/awa/versions/customer")}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}