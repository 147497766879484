import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const QuotasContext = createContext();

export default function QuotasProvider(props) {
  const [quotasReached, setQuotasReached] = useState(false);

  return (
    <QuotasContext.Provider value={[quotasReached, setQuotasReached]}>
      {props.children}
    </QuotasContext.Provider>
  );
}

QuotasProvider.propTypes = {
  children: PropTypes.any,
};
