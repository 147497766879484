import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  Export,
  FilterRow,
  Grouping,
  Paging,
  SearchPanel
} from "devextreme-react/data-grid";
import { indexBy, prop } from "ramda";
import React, { useMemo, useState } from "react";
import { exportToXlsx } from "../airwork/helper";
import { GET_DETAIL_CUSTOMERS, GET_LICENCES, UPDATE_CUSTOMERS } from "./query";
import dayjs from "dayjs";

const useStyles = makeStyles({
  paper: {
    margin: "1rem",
    padding: "20px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    height: "95%"
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    height: "calc(100vh - 80px)",
    overflowY: "hidden"
  },
  tooltipContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem"
  }
});

const DetailCustomers = () => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleClose = e => setOpenSuccess(false);
  const classes = useStyles();

  const {
    loading: detailCustomersLoading,
    error: detailCustomersError,
    data: detailCustomersQuery
  } = useQuery(GET_DETAIL_CUSTOMERS, {
    fetchPolicy: "no-cache"
  });
  const {
    loading: licencesLoading,
    error: licencesError,
    data: licencesQuery
  } = useQuery(GET_LICENCES, {
    fetchPolicy: "no-cache"
  });

  const [updateCustomers, { error: errorUpdating }] = useMutation(
    UPDATE_CUSTOMERS,
    {
      onCompleted: () => setOpenSuccess(true),

      refetchQueries: [
        {
          query: GET_DETAIL_CUSTOMERS
        }
      ]
    }
  );

  const details = useMemo(() => {
    if (!detailCustomersQuery?.DetailCustomers) return [];
    return detailCustomersQuery.DetailCustomers;
  }, [detailCustomersQuery]);
  const licences = useMemo(() => {
    if (!licencesQuery?.Licences) return [];
    return licencesQuery.Licences;
  }, [licencesQuery]);

  const onTableSave = e => {
    const customersLicences = [];
    const customersAndExpirationDate = [];
    e.changes.forEach(item => {
      customersLicences.push({
        trigram: item.data.clientTrigramme,
        licences: item.data.licences.map(({ id, label, maxAuthorized }) => ({
          id,
          label,
          maxAuthorized
        }))
      });
      customersAndExpirationDate.push({
        trigram: item.data.clientTrigramme,
        licenceExpirationDate: item.data.licenceExpirationDate
          ? dayjs(item.data.licenceExpirationDate).format("YYYY-MM-DD")
          : null
      });
    });

    updateCustomers({
      variables: {
        customersLicences,
        customersAndExpirationDate
      }
    });
  };

  const onRowUpdating = e => {
    const row = e.oldData;
    Object.entries(e.newData).forEach(([key, value]) => {
      if (key === "licenceExpirationDate") {
        row.licenceExpirationDate = value;
      } else {
        const index = key.replace(/\D/g, "");
        const licence = row.licences[index];
        licence.maxAuthorized = value.maxAuthorized;
        row.licences[index] = licence;
      }
    });
    return row;
  };

  const onEditingChange = rows => {
    rows.forEach(row => {
      const newRow = { ...row.key };
      Object.entries(row.data).forEach(([key, value]) => {
        if (key === "licenceExpirationDate") {
          newRow.licenceExpirationDate = value;
        } else {
          const index = key.replace(/\D/g, "");
          const licence = newRow.licences[index];
          licence.maxAuthorized = value.maxAuthorized;
          newRow.licences[index] = licence;
        }
      });
      row = newRow;
    });
    return rows;
  };
  const cellPrepared = e => {
    if (e.rowType === "data") {
      if (
        e.column.dataField === "licenceExpirationDate" &&
        !!e.data.licenceExpirationDate
      ) {
        e.cellElement.style.cssText =
          "color: red; font-weight: bold;  text-align: center";
      }
    }
  };
  if (detailCustomersLoading || updateCustomers.loading || licencesLoading)
    return (
      <Grid container>
        <Grid item xs={12} className={classes.loading}>
          <CircularProgress data-testid="loading" />
        </Grid>
      </Grid>
    );
  return (
    <Grid container className={classes.container}>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Data has been successfully saved!
        </Alert>
      </Snackbar>
      <Grid item xs={12} style={{ height: "100%" }}>
        <Paper className={classes.paper} elevation={3} sm={12}>
          <Typography component="h1" variant="h4" className={classes.title}>
            Detail clients
          </Typography>
          {detailCustomersError ? (
            <Alert severity="error">
              An error occured while fetching data :{" "}
              {detailCustomersError?.message}
            </Alert>
          ) : null}
          {licencesError ? (
            <Alert severity="error">
              An error occured while fetching data : {licencesError?.message}
            </Alert>
          ) : null}
          {errorUpdating ? (
            <Alert severity="error">An error occured while saving data</Alert>
          ) : null}
          <Typography variant="caption">(1): Donnée modifiable</Typography>
          <DataGrid
            dataSource={details}
            rowKey="id"
            height="calc(100% - 50px)"
            allowColumnResizing
            onCellPrepared={cellPrepared}
            allowColumnReordering
            onSaved={onTableSave}
            onRowUpdating={onRowUpdating}
            showBorders
            rowAlternationEnabled
            columnAutoWidth
            onEditorPreparing={e => {
              if (e.dataField === "licenceExpirationDate") {
                e.editorOptions.min = new Date();
              }
            }}
            onExporting={e =>
              exportToXlsx(e, {
                title: `detail-customers`
              })
            }
          >
            <FilterRow visible={true} />
            <SearchPanel visible={true} width={400} />
            <Grouping autoExpandAll={false} />
            <Export enabled={true} formats={"xlsx"} />
            <Editing
              allowUpdating={true}
              mode="batch"
              onChangesChange={onEditingChange}
            />
            <Paging defaultPageSize={50} />
            <Column
              caption="Client"
              alignment="center"
              fixed={true}
              fixedPosition="left"
            >
              <Column
                dataField="clientTrigramme"
                caption="Trigram"
                width="auto"
                fixed={true}
              />
              <Column
                dataField="clientNom"
                caption="Nom"
                width="auto"
                allowEditing={false}
              />
              <Column
                dataField="clientNbTotalAc"
                caption="Total AC"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
              <Column
                dataField="licenceExpirationDate"
                width="auto"
                dataType="date"
                allowEditing={true}
                format={"dd/MM/yyyy"}
                headerCellRender={() => (
                  <div className={classes.tooltipContainer}>
                    "Lic. exp. date (1)"
                    <Tooltip
                      title="Un message d'avertissement non bloquant sera affiché avant la date saisie. L'accès aux applications sera bloqué à partir de la date saisie. Un délai de quelques minutes est nécessaire (cache) pour que le message soit affiché sur l'interface du client"
                      children={<InfoIcon fontSize="small" />}
                    />
                  </div>
                )}
              />
            </Column>
            <Column
              headerCellRender={() => (
                <div className={classes.tooltipContainer}>
                  A/C en serice
                  <Tooltip
                    title="En service = A/C total - A/C archivé"
                    children={<InfoIcon fontSize="small" />}
                  />
                </div>
              )}
              alignment="center"
            >
              <Column
                dataField="clientNbTotalAcEnService"
                caption="Total"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
              <Column
                dataField="clientNbTotalAvionEnService"
                caption="Avions"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
              <Column
                dataField="clientNbTotalHelicoEnService"
                caption="Hélicos"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
            </Column>
            <Column caption="A/C en exploitation" alignment="center">
              <Column
                dataField="clientNbTotalAcEnExploitation"
                caption="Total"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
              <Column
                dataField="clientNbTotalAvionEnExploitation"
                caption="Avions"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
              <Column
                dataField="clientNbTotalHelicoEnExploitation"
                caption="Hélicos"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
            </Column>
            <Column caption="AIRTIME" alignment="center">
              <Column
                dataField="AirtimeActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
              <Column
                dataField="AIRTIMENbLicences"
                caption="Nb lic."
                width="auto"
                dataType="number"
                allowEditing={false}
              />
            </Column>
            <Column caption="AIRSTOCK" alignment="center">
              <Column
                dataField="AirstockActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
              <Column
                dataField="AIRSTOCKNbLicences"
                caption="Nb lic."
                width="auto"
                dataType="number"
                allowEditing={false}
              />
            </Column>
            <Column caption="AIRSTAT" alignment="center">
              <Column
                dataField="AirstatActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="eLog" alignment="center">
              <Column
                dataField="ElogActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
              <Column
                dataField="ElogNbAc"
                caption="Nb A/C"
                width="auto"
                dataType="number"
                allowEditing={false}
              />
            </Column>
            <Column caption="eWork" alignment="center">
              <Column
                dataField="EworkActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="eDamage" alignment="center">
              <Column
                dataField="EdamageActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column
              headerCellRender={() => (
                <>
                  Licences <Typography variant="caption">(1)</Typography>
                </>
              )}
              alignment="center"
            >
              {licences.map((item, i) => {
                return (
                  <Column
                    key={item.id}
                    dataField={`licences[${i}].maxAuthorized`}
                    caption={item.label}
                    calculateCellValue={data => {
                      const licencesById = indexBy(
                        prop("id"),
                        data.licences || []
                      );
                      return licencesById[item.id]?.maxAuthorized || 0;
                    }}
                    dataType="number"
                    width="auto"
                    allowEditing={true}
                  />
                );
              })}
            </Column>
            <Column caption="eQual" alignment="center">
              <Column
                dataField="EqualActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="KX modèle" alignment="center">
              <Column
                dataField="EconfigActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="eFleet" alignment="center">
              <Column
                dataField="EfleetActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="easyDATA" alignment="center">
              <Column
                dataField="EasyDataActive"
                caption="Activé"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="Nom du serveur" alignment="center">
              <Column
                dataField="ServeurBaseClient"
                caption="et de la base client"
                width="auto"
                allowEditing={false}
              />
            </Column>
            <Column caption="Erreur" alignment="center">
              <Column
                dataField="AirpackError"
                caption="Airpack"
                dataType="boolean"
                width="auto"
                allowEditing={false}
              />
            </Column>
          </DataGrid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DetailCustomers;
