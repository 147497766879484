export const SIDEMENU_WIDTH = 240;
export const MONITORING_REFRESH_INTERVAL_SECONDS = 30;
export const SUPPORT_REFRESH_INTERVAL_SECONDS = 10 * 60;
export const AWA_VERSIONS_REFRESH_INTERVAL_SECONDS = 10 * 60;
export const AWS_ACCOUNT_USAGE_INTERVAL_SECONDS = 10 * 60;
export const AUTH_TOKEN = "ADMIN_TOKEN";
export const KTOKEN = "keycloakToken";

export const AWSQuotas = {
  s3: {
    production: 100,
    staging: 1000,
    development: 100
  },
  snsSubscriptions: {
    default: 900
  },
  apiGateway: {
    edge: 120,
    regional: 600
  }
};

export const eAWSProfiles = {
  DEV: "development",
  STAGING: "staging",
  PROD: "production",
  GENERAL: "general"
};

export const WARN_MAX_USAGE_PERCENT = 100;
