import gql from "graphql-tag";

export const GET_LICENCES = gql`
  query {
    Licences {
      id
      label
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_DETAIL_CUSTOMERS = gql`
  query {
    DetailCustomers {
      id
      ServeurBaseClient
      clientTrigramme
      clientNom
      clientNbTotalAc
      clientNbTotalAcEnService
      clientNbTotalAvionEnService
      clientNbTotalHelicoEnService
      clientNbTotalAcEnExploitation
      clientNbTotalAvionEnExploitation
      clientNbTotalHelicoEnExploitation
      AirtimeActive
      AIRTIMENbLicences
      AirstockActive
      AIRSTOCKNbLicences
      AirstatActive
      EqualActive
      EconfigActive
      ElogActive
      ElogNbAc
      EworkActive
      EfleetActive
      EdamageActive
      EasyDataActive
      AirpackError
      licenceExpirationDate
      licences {
        id
        label
        maxAuthorized
      }
    }
  }
`;

export const UPDATE_CUSTOMERS = gql`
  mutation UpdateCustomers(
    $customersAndExpirationDate: [CustomersAndExpirationDate]!
    $customersLicences: [UpdateCustomersLicencesInput]!
  ) {
    UpdateCustomers(
      customersLicences: $customersLicences
      customersAndExpirationDate: $customersAndExpirationDate
    )
  }
`;
