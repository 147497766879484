import { Button, CircularProgress, Paper } from "@material-ui/core";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import keycloak from "../keycloak";

export default function Secured({ children, onTokenReceived }) {
  const [keycloakState, setKeycloakState] = useState("");
  const [allowAccess, setAllowAccess] = useState();

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<CircularProgress />}
      onTokens={t => {
        localStorage.setItem("keycloakToken", t.token);
        if (keycloak.authenticated && keycloakState === "onAuthSuccess") {
          onTokenReceived(t.token);
          setAllowAccess(true);
        }
      }}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false
      }}
      onEvent={e => {
        setKeycloakState(e);
      }}
    >
      {allowAccess === false ? <NotAuthorized /> : children}
    </ReactKeycloakProvider>
  );
}

const NotAuthorized = () => {
  const { keycloak } = useKeycloak();

  return (
    <Paper
      title={`Sorry ${keycloak.tokenParsed?.given_name}, you are not authorized to access this resource 😿`}
      extra={
        <Button type="primary" onClick={() => keycloak.logout()}>
          Logout
        </Button>
      }
    />
  );
};
