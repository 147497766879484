import { Link as MLink /*, Divider*/ } from "@material-ui/core/";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BugReportIcon from "@material-ui/icons/BugReport";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LinkIcon from "@material-ui/icons/Link";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ToysSharp from "@material-ui/icons/ToysSharp";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import GroupIcon from "@material-ui/icons/Group";
import { Link } from "@reach/router";
import React from "react";
import BusinessIcon from "@material-ui/icons/Business";

export const mainListItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <MLink to="/" component={Link}>
        <ListItemText primary="Dashboard" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ToysSharp />
      </ListItemIcon>
      <MLink to="/deployments" component={Link}>
        <ListItemText primary="Deployments" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LockOpenIcon />
      </ListItemIcon>
      <MLink to="/customer/modules/rights" component={Link}>
        <ListItemText primary="Customer modules" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LinkIcon />
      </ListItemIcon>
      <MLink to="/customer/url" component={Link}>
        <ListItemText primary="Customer url" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BugReportIcon />
      </ListItemIcon>
      <MLink to="/" component={Link}>
        <ListItemText primary="Mantis monitoring" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AccountTreeIcon />
      </ListItemIcon>
      <MLink to="/awa/temporary_versions" component={Link}>
        <ListItemText primary="Temporary Versions" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <MLink to="/awa-users" component={Link}>
        <ListItemText primary="Awa users" />
      </MLink>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <MLink to="/detail-customers" component={Link}>
        <ListItemText primary="Detail clients" />
      </MLink>
    </ListItem>
  </div>
);

export const secondaryListItems = <div></div>;
