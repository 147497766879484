import { useQuery } from "@apollo/react-hooks";
import { useMemo, useEffect } from "react";
import { keyBy } from "lodash";
import gql from "graphql-tag";
import { satisfies, coerce } from "semver";

const GET_MODULES = gql`
  query {
    AWAModules {
      id
      name
      isMonorepo
      removedInVersion
      monorepoLastVersionWithout
    }
  }
`;

export default ({
  versionModules,
  versionName,
  excludedModules = [],
  setExcludedModules = () => {}
}) => {
  const { loading, data } = useQuery(GET_MODULES);
  const versionsByModuleId = useMemo(
    () =>
      versionModules && Object.keys(versionModules).length
        ? keyBy(versionModules, "moduleId")
        : {},
    [versionModules]
  );
  const isMonorepoExcluded = useMemo(
    () => (excludedModules || []).some(m => m === "27672694"),
    [excludedModules]
  );

  const modules = useMemo(() => {
    const tmpModules =
      data &&
      data.AWAModules &&
      versionModules &&
      Object.keys(versionModules).length
        ? data.AWAModules.reduce((acc, m) => {
            const isMonorepoModule =
              m.isMonorepo === "true" && !versionsByModuleId[m.id];

            if (!isMonorepoModule && !versionsByModuleId[m.id]) return acc;

            const moduleName = !isMonorepoModule
              ? m.name
              : `${m.name}-monorepo`;
            const moduleVersion = !isMonorepoModule
              ? versionsByModuleId[m.id].name
              : "";

            const module = {
              id: m.id,
              name: moduleName,
              version: moduleVersion,
              isMonorepoModule,
              removedInVersion: m.removedInVersion,
              order: m.name === "monorepo" ? -1 : 0
            };

            if (isMonorepoModule || m.name === "monorepo") {
              if (
                module.removedInVersion &&
                satisfies(coerce(versionName), `>=${module.removedInVersion}`)
              ) {
                // on filtre les modules removed in version
                return {
                  ...acc,
                  excludedModules: [...(acc.excludedModules || []), module]
                };
              }
              return {
                ...acc,
                monorepoModules: [...(acc.monorepoModules || []), module]
              };
            }
            return {
              ...acc,
              otherModules: [...(acc.otherModules || []), module]
            };
          }, {})
        : {};
    return {
      ...tmpModules,
      monorepoModules: tmpModules.monorepoModules?.sort((a, b) =>
        a.order < b.order ? -1 : a.name < b.name ? -1 : 1
      )
    };
  }, [data, versionModules, versionsByModuleId, versionName]);

  useEffect(() => {
    if (!isMonorepoExcluded || !modules) return;

    const monorepoModuleIds = modules.monorepoModules.map(m => m.id);
    const excludedFromMonorepo = (modules.excludedModules || []).map(m => m.id);
    const excluded = Array.from(
      new Set([
        ...excludedModules,
        ...monorepoModuleIds,
        ...excludedFromMonorepo
      ])
    );
    setExcludedModules(excluded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMonorepoExcluded, setExcludedModules, modules]);

  return {
    modulesCount:
      (modules?.monorepoModules?.length || 0) +
      (modules?.otherModules?.length || 0),
    excludedModulesCount: modules.excludedModules?.length || 0,
    modules,
    isMonorepoExcluded,
    loading
  };
};
