import React from "react";
import { makeStyles, Typography, Tooltip, Button } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  smallCapiton: {
    fontSize: "0.7rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    marginLeft: theme.spacing(1) * -1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1) / 2,
  },
  color: {
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

export default function BaseIconTextButton({ label, tooltip, disabled, onClick, icon }) {
  const classes = useStyles();
  return (
    <Tooltip title={tooltip}>
      <Button
        className={classes.color}
        onClick={onClick}
        disabled={!!disabled}
      >
        {icon}
        <Typography className={classes.smallCapiton}>
          {label}
        </Typography>
      </Button>
    </Tooltip>
  );
}

BaseIconTextButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any,
  tooltip: PropTypes.string,
  disabled: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};
