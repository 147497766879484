import React from "react";
import Title from "../Title";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import { MONITORING_REFRESH_INTERVAL_SECONDS } from "../utils/constants";

export const GET_MONITORING_EVENT = gql`
  {
    MonitoringEvents {
      id
      client {
        name
      }
      check {
        name
        output
      }
    }
  }
`;

export default function Monitoring() {
  const { loading, error, data } = useQuery(GET_MONITORING_EVENT, {
    pollInterval: MONITORING_REFRESH_INTERVAL_SECONDS * 1000
  });

  return (
    <>
      <Title>Monitoring</Title>
      {loading && <Grid 
                        container 
                        justify="center"
                    >
                        <Grid item>
                            <CircularProgress data-testid="loading" />
                        </Grid>
                    </Grid>}
      {error && error.message}
      {!loading && !error && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Output</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.MonitoringEvents && data.MonitoringEvents.map(monitoringEvent => (
              <TableRow key={monitoringEvent.id}>
                <TableCell>{monitoringEvent.client.name}</TableCell>
                <TableCell>{monitoringEvent.check.output}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
