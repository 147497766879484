import React, { useState } from "react";
import {
  Link as MLink,
  makeStyles,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  TablePagination,
  Switch,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import { useToggle } from "react-use";
import { compare as compareSemVer, prerelease } from "semver";
import moment from "moment";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { AWA_VERSIONS_REFRESH_INTERVAL_SECONDS } from "../../utils/constants";
import AddVersionButton from "./AddVersionButton";
import Title from "../../Title";
import { Link } from "@reach/router";
import Filter from "../../utils/Filter";

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(0)
  }
}));
export const GET_AWA_VERSIONS = gql`
  {
    AWAVersions {
      id
      name
      createdAt
      deletedAt
    }
  }
`;

export default function AWAVersions() {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const { loading, error, data } = useQuery(GET_AWA_VERSIONS, {
    pollInterval: AWA_VERSIONS_REFRESH_INTERVAL_SECONDS * 1000,
    fetchPolicy: "cache-and-network",
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const filterOutPreRelease = version => !prerelease(version.name);
  const [hidePrerelease, toggleHidePrerelease] = useToggle(true);
  const sortedVersions = (data ? data.AWAVersions || [] : [])
    .sort((a, b) => compareSemVer(b.name, a.name))
    .filter(version => version.name.includes(filter) && !version.deletedAt).filter(version => hidePrerelease ? filterOutPreRelease(version) : true);
  const versionByList = 3;
  return (
    <>
      <Title>AWA versions</Title>
      {loading && <CircularProgress data-testid="loading" />}
      {error && error.message}
      {!loading && !error && (
        <>
          <List>
            {sortedVersions.slice(page * versionByList, page * versionByList + versionByList).map(
              awaVersion => (
                <div key={awaVersion.id}>
                  <ListItem dense alignItems="flex-start">
                    <ListItemText
                      primary={
                        <MLink
                          to={`/awa/versions/${awaVersion.id}`}
                          component={Link}
                        >
                          {awaVersion.name}
                        </MLink>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {moment(awaVersion.createdAt).format("DD-MM-YYYY")}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </div>
              )
            )}
          </List>
          <TablePagination
            rowsPerPageOptions={versionByList}
            component="div"
            count={sortedVersions.length}
            rowsPerPage={versionByList}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
          />
        </>
      )}
      <Grid
        container
        wrap={"nowrap"}
        className={classes.seeMore}
        justify="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                id="hidePrerelease"
                name="hidePrerelease"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                checked={hidePrerelease}
                onChange={() => toggleHidePrerelease()}
                value={hidePrerelease}
              />
            }
            label={`Hide pre-release`}
          />
        </Grid>
        <Grid item>
          <AddVersionButton />
        </Grid>
        <Grid item>
          <Filter
            placeholder={"Recherche"}
            value={filter}
            onChange={e => setFilter(e)}
          />
        </Grid>
      </Grid>
    </>
  );
}
