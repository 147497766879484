import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import { DataGrid } from "devextreme-react";
import { Column, FilterRow } from "devextreme-react/data-grid";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import useAddLogMutation from "../log/useAddLogMutation";
import Modal from "../utils/Modal";

const DELETE_STAGE = gql`
  mutation deleteStage($deleteStage: AWADeleteStageInput!) {
    deleteStage(deleteStage: $deleteStage)
  }
`;

const GET_BETAS = gql`
  query($excludedStages: [String]!) {
    AWABetasList(excludedStages: $excludedStages) {
      id
      customer_trigram
      awa_version
      airpack_version
      stage
      created_at
      updated_at
    }
  }
`;

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    position: "relative"
  }
}));

DeleteStage.propTypes = {
  stage: PropTypes.string
};

const PARALLELIZE_DELETION = false;

export default function DeleteStage({ stage = "beta" }) {
  const excludedStages = ["BETANIGHLY", "ADMIN", "BETAAU", "PROD", "DEV"];
  const classes = useStyles();

  const [deleteErrors, setDeleteErrors] = useState([]);
  const [deleteSuccesses, setDeleteSuccesses] = useState([]);
  const [deleteLoadings, setDeleteLoadings] = useState([]);
  const isDeleting = deleteLoadings.length > 0;

  const { loading: betaLoading, error: betaError, data: betasList, refetch } = useQuery(
    GET_BETAS,
    {
      fetchPolicy: "no-cache",
      variables: { excludedStages }
    }
  );

  const [registerLog] = useAddLogMutation();

  const [open, setOpen] = useState(false);
  const [stageVersionToDelete, setStageVersionToDelete] = useState(null);


  const betas = betasList ? betasList?.AWABetasList || [] : [];

  const DeleteCell = useCallback(({data, loading}) => <DeleteButton
    data={data}
    onClick={(data, action) => {
      setStageVersionToDelete({
        stage: data.stage,
        version: data.airpack_version,
        action,
      });
      setOpen(true);
    }}
    onDeleteSuccess={(successMsg) => {
      setDeleteSuccesses((state) => ([...state, successMsg]));
      setDeleteLoadings((state) => [...state.filter(s => s.id !== data.stage)]);
      setDeleteErrors((state) => [...state.filter(s => s.id !== data.stage)]);
      refetch();
    }}
    onDeleteError={(errorMsg) => {
      setDeleteErrors((state) => ([...state, errorMsg]));
      setDeleteSuccesses((state) => [...state.filter(s => s.id !== data.stage)]);
      setDeleteLoadings((state) => [...state.filter(s => s.id !== data.stage)]);
    }}
    onDeleting={(loadingMsg) => {
      setDeleteLoadings((state) => ([...state, loadingMsg]));
      setDeleteSuccesses((state) => [...state.filter(s => s.id !== data.stage)]);
      setDeleteErrors((state) => [...state.filter(s => s.id !== data.stage)]);
    }}
    loading={loading}
    disabled={isDeleting}
    />, [setDeleteSuccesses, setDeleteErrors, setDeleteLoadings, setStageVersionToDelete, setOpen, refetch, isDeleting]);



  const handleDeleteBeta = async () => {
    const registeredLog = await registerLog({
      variables: {
        log: {
          taskReference: `delete-${stageVersionToDelete.stage}`,
          title: `Delete Stage ${stageVersionToDelete.stage}`,
          status: "INFO"
        }
      }
    });

    return stageVersionToDelete.action(registeredLog.data.addLog.id);
  };


  return (
    <Container>
      {betaLoading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {betaError && (
        <Alert severity="error">
          An error occured while fetching data :{" "}
          {betaError?.message || "an error occured"}
        </Alert>
      )}
      {deleteErrors.map(error => <Alert severity="error">
        An error occured while deleting beta {error.id} :{" "}
        {error.message || "an error occured"}
      </Alert>)}
      {deleteSuccesses.map(success => <Alert severity="success">Beta {success.id} has been succesfully deleted.</Alert>)}
      {deleteLoadings.map(success => <Alert severity="success">Beta {success.id} is being deleted...</Alert>)}

      {!betaLoading && (
        <>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" className={classes.title}>
              Betas
            </Typography>
            <Typography component="p" variant="p">
              dont le stage est différent de : {excludedStages.join(" ")}
            </Typography>
            <DataGrid
              dataSource={betas}
              rowKey="id"
              allowColumnResizing
              allowColumnReordering
              showBorders
              rowAlternationEnabled
              columnAutoWidth
            >
              <FilterRow visible={true} />
              <Column dataField="stage" caption="stage" />
              <Column dataField="customer_trigram" caption="Trigram" />
              <Column dataField="airpack_version" caption="Version" />
              <Column
                dataField="created_at"
                caption="date de création"
                calculateCellValue={rowData =>
                  dayjs(rowData.created_at).format("DD/MM/YYYY, HH:mm")
                }
                calculateSortValue="created_at"
              />
              <Column
                dataField="updated_at"
                caption="date de mise à jour"
                calculateCellValue={rowData =>
                  dayjs(rowData.updated_at).format("DD/MM/YYYY, HH:mm")
                }
                calculateSortValue="updated_at"
              />
              <Column caption="action" cellRender={({data}) =>
                <DeleteCell
                  data={data}
                  loading={deleteLoadings.some(l => l.id === data.stage)}
                />}
              />
            </DataGrid>
          </Paper>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            onValid={async () => {
              setOpen(false);
              handleDeleteBeta();
            }}
            title={"Delete Beta"}
            type={"warning"}
            actionName={"delete Beta"}
          >
            <Grid item>
              <Typography>
                Are you sure you want to delete this Beta{" "}
                {stageVersionToDelete?.stage || ""} ?
              </Typography>
            </Grid>
          </Modal>
        </>
      )}
    </Container>
  );
}

const DeleteButton = ({data, onClick, onDeleteSuccess, onDeleteError, onDeleting, loading, disabled}) => {

  const [
    deleteStage
  ] = useMutation(DELETE_STAGE, {
    onCompleted: data => {
      onDeleteSuccess({ id: data.stage });
    },
    onError: (error) => {
      onDeleteError({ id: data.stage, message: error.message });
    }
  });

  const deleteAction = (logChannelId) => {
    deleteStage({
      variables: {
        deleteStage: {
          channelId: logChannelId,
          stage: data.stage,
          versionName: data.airpack_version
        }
      }
    });

    onDeleting({ id: data.stage });
  }

  return (
    <IconButton
      data-testid="deleteButton"
      onClick={() => onClick(data, deleteAction)}
      disabled={PARALLELIZE_DELETION ? false : disabled}
    >
      {loading && <CircularProgress size={14} />}
      {!loading && <DeleteIcon color="primary" />}
    </IconButton>
  );
};
