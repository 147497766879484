import React from "react";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => {
    return {
        titlewarning: {
            // backgroundColor: "#ff6f00",
            // color: theme.palette.error.contrastText,
        },
        titleerror: {
            // backgroundColor: theme.palette.error.main,
            // color: theme.palette.error.contrastText,
        },
        titleinfo: {
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.error.contrastText,
        },
        warning: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
            '&:hover': {
                background: theme.palette.warning.dark,
            },
        },
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                background: theme.palette.secondary.main,
            },
        },
        info: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                background: theme.palette.primary.dark,
            },
        },
        modal :{
            position:"absolute",
            backgroundColor: "#ffffff",
            top: `30%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            width:"50%",
        },
        modalCloseButton:{
            borderRadius: "0px"
        },
        modalTitle: {
            backgroundColor: "#eeeeee",
            '& > *': {
                padding:theme.spacing(1)
            },
            '& > *:first-child': {
                flexGrow: "1",
            },
            '& > *:last-child': {
                flexGrow: "0",
            },
        },
        modalContent: {
            padding:theme.spacing(2)
        },
        modalFooter: {
            display: "flex",
            justifyContent: "flex-end",
            padding: theme.spacing(1),
        },
    }
});

ModalADS.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node,
    buttons: PropTypes.node,
    type: PropTypes.string,
    actionName: PropTypes.string,
    onValid: PropTypes.func.isRequired,
};

export default function ModalADS({ open, onClose, title, children, buttons, type = "info", actionName, onValid }) {
    const classes = useStyles(type);

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
            open={open}
            onClose={onClose}
        >
            <Grid
                container
                direction="column"
                className={classes.modal}
            >
                <Grid 
                    item 
                    container
                    id={"modal-title"}
                    className={classes.modalTitle}
                    wrap={"nowrap"}
                    direction="row"
                >
                    <Grid item className={classes[`title${type}`]}>
                        <Typography>{title}</Typography>
                    </Grid>
                        <Button
                            className={classes.modalCloseButton}
                            onClick={()=>onClose()}
                        >
                            X
                        </Button>
                </Grid>
                <Grid 
                    item 
                    id={"modal-content"}
                    className={classes.modalContent}
                >
                    {children}
                </Grid>
                <Grid item className={classes.modalFooter}>
                    {
                        buttons ? buttons:
                        <Button
                            variant="contained"
                            onClick={async () => { if(onValid){await onValid();}}}
                            className={classes[type]}
                        >
                            {actionName}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Modal>
    );
}
