import React from "react";
import {
  Grid,
  CircularProgress,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import ReadonlyModule from "../../components/ReadonlyModule";
import PropTypes from "prop-types";

ModulesVersion.propTypes = {
  versionModules: PropTypes.array,
  select: PropTypes.bool,
  onChange: PropTypes.func,
  excludedModules: PropTypes.array
};

export default function ModulesVersion({
  excludedModules,
  modules,
  isMonorepoExcluded,
  loading,
  select = false,
  onChange = () => { }
}) {
  return (
    <Grid item container>
      {loading && <CircularProgress data-testid="loading" />}
      {!select ? (
        <>
          {
            modules?.otherModules?.length &&
            <Grid item container xs={12} md={8} direction="row">
              {modules?.otherModules?.map(m => (
                <Grid item xs={12} md={6}>
                  <ReadonlyModule module={m} />
                </Grid>
              ))}
            </Grid>
          }
          {
            modules?.monorepoModules?.length &&
            <Grid item xs={12} md={4} direction="row">
              {modules?.monorepoModules?.map(m => (
                <ReadonlyModule module={m} />
              ))}
            </Grid>
          }
        </>
      ) : (
        <>
          {
            modules?.otherModules?.length &&
            <Grid item xs={12}>
              {modules?.otherModules?.map(m => (
                <SwitchableModule
                  module={m}
                  excludedModules={excludedModules}
                  isMonorepoExcluded={isMonorepoExcluded}
                  onSwitch={onChange}
                />
              ))}
            </Grid>
          }
          {
            modules?.monorepoModules?.length &&
            <Grid item xs={12}>
              {modules?.monorepoModules?.map(m => (
                <SwitchableModule
                  module={m}
                  excludedModules={excludedModules}
                  isMonorepoExcluded={isMonorepoExcluded}
                  onSwitch={onChange}
                />
              ))}
            </Grid>
          }
        </>
      )}
    </Grid>
  );
}

const SwitchableModule = ({
  module,
  excludedModules,
  isMonorepoExcluded,
  onSwitch
}) => (
  <FormControlLabel
    control={
      <Switch
        disabled={
          module.isMonorepoModule &&
          isMonorepoExcluded &&
          module.name !== "monorepo"
        }
        checked={
          module.isMonorepoModule && isMonorepoExcluded
            ? false
            : !excludedModules.includes(module.id)
        }
        onChange={e => {
          const id = e.target.value;
          let sm = excludedModules;
          if (excludedModules.includes(id)) {
            sm = excludedModules.filter(mid => mid !== id);
          } else {
            sm = [...excludedModules, id];
          }
          onSwitch(sm);
        }}
        value={`${module.id}`}
        color="primary"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    }
    label={
      <span>
        <span>{`${module.name}`}</span>{" "}
        <span style={{ fontSize: "10px" }}>
          {module.version && `-${module.version}`}
        </span>
      </span>
    }
  />
);
