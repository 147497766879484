import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
  Typography
} from "@material-ui/core";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import PropTypes from "prop-types";

const GET_CUSTOMER_FOR_VERSION = gql`
  query($name: String!) {
    AWACustomerByVersionName(name: $name) {
      id
      trigram
      airpackVersion
      awaVersion
      stage
    }
  }
`;

CustomersVersion.propTypes = {
  versionName: PropTypes.string.isRequired,
  includedCustomers: PropTypes.array,
  select: PropTypes.bool,
  onChange: PropTypes.func,
  filter: PropTypes.func
};

export default function CustomersVersion({
  versionName,
  includedCustomers,
  filter,
  select = false,
  onChange = () => {}
}) {
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  React.useEffect(() => {
    setSelectedCustomers(includedCustomers);
  }, [includedCustomers]);

  const { loading, error, data } = useQuery(GET_CUSTOMER_FOR_VERSION, {
    variables: { name: versionName }
  });
  const [
    orderedFilteredCustomers,
    setOrderedFilteredCustomers
  ] = React.useState([]);

  React.useEffect(() => {
    onChange(
      orderedFilteredCustomers
        .filter(v => !v.stage.startsWith("BETA"))
        .map(v => v.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedFilteredCustomers]);

  React.useEffect(() => {
    if (data && data.AWACustomerByVersionName) {
      filter &&
        setOrderedFilteredCustomers(
          orderBy(data.AWACustomerByVersionName.filter(filter), "trigram")
        );
      !filter &&
        setOrderedFilteredCustomers(
          orderBy(data.AWACustomerByVersionName, "trigram")
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter]);

  return (
    <Grid container spacing={1}>
      {loading && <CircularProgress data-testid="loading" />}
      {!loading && !orderedFilteredCustomers.length && (
        <Typography>No customers</Typography>
      )}
      {!error && orderedFilteredCustomers.length ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {select && (
                  <Checkbox
                    checked={
                      selectedCustomers.length ===
                      orderedFilteredCustomers.length
                    }
                    inputProps={{ "aria-labelledby": "all" }}
                    color="primary"
                    onChange={() => {
                      if (!orderedFilteredCustomers.length) {
                        return;
                      }
                      if (
                        selectedCustomers.length ===
                        orderedFilteredCustomers.length
                      ) {
                        onChange([]);
                      } else {
                        onChange(orderedFilteredCustomers.map(c => c.id));
                      }
                    }}
                  />
                )}
              </TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>AIRPACK</TableCell>
              <TableCell>AWA</TableCell>
              <TableCell>Stage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedFilteredCustomers.map(
              ({ id, trigram, airpackVersion, awaVersion, stage }) => {
                return (
                  <TableRow
                    onClick={e => {
                      let sc = selectedCustomers;
                      if (selectedCustomers.includes(id)) {
                        sc = selectedCustomers.filter(mid => !(mid === id));
                      } else {
                        sc = [...selectedCustomers, id];
                      }
                      onChange(sc);
                    }}
                    key={id}
                  >
                    <TableCell padding="checkbox">
                      {select && (
                        <Checkbox
                          onChange={e => {
                            let sc = selectedCustomers;
                            if (selectedCustomers.includes(id)) {
                              sc = selectedCustomers.filter(
                                mid => !(mid === id)
                              );
                            } else {
                              sc = [...selectedCustomers, id];
                            }
                            onChange(sc);
                          }}
                          color="primary"
                          checked={selectedCustomers.includes(id)}
                          inputProps={{ "aria-labelledby": id }}
                          value={id}
                        />
                      )}
                    </TableCell>
                    <TableCell>{trigram}</TableCell>
                    <TableCell>{airpackVersion}</TableCell>
                    <TableCell>{awaVersion}</TableCell>
                    <TableCell>{stage}</TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      ) : null}
    </Grid>
  );
}
