import React from "react";
import {
  makeStyles,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Log from "../log/Log";

const useStyles = makeStyles(theme => ({
  title: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
  },
  paper: {
      padding: theme.spacing(2),
      position:"relative",
      height: "calc(100vh - 110px)"
  },
}));

WatchLog.propTypes = {
    id: PropTypes.string,
};

export default function WatchLog({ id }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" className={classes.title}>
            Logs
        </Typography>
        {
            id && <Log 
                log={{
                    id,
                    title : "...",
                    status: "INFO",
                    createdAt: new Date()
                }}
                standAlone={true}
            />
        }
      </Paper>
    </Container>
  );
}
