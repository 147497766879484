import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Tooltip, IconButton } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";
import { LoggedContext } from "../LoggedContext";
import keycloak from "../keycloak";

export default function LoginButton() {
  return (
    <LoggedContext.Consumer>
      {({ logged, setLogged }) => (
        <Tooltip title={`${logged ? "Disconnect" : "Login"}`}>
          <IconButton
            color="inherit"
            data-testid="deleteButton"
            onClick={() => {
              if (logged) {
                setLogged(false);
                keycloak.logout();
              }
              navigate(`/login`);
            }}
          >
            {logged && <CancelPresentationIcon />}
            {!logged && <ExitToAppIcon />}
          </IconButton>
        </Tooltip>
      )}
    </LoggedContext.Consumer>
  );
}

LoginButton.propTypes = {
  versionId: PropTypes.string.isRequired,
  disabled: PropTypes.any
};
