import React, { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
    CircularProgress,
    Typography,
    Paper,
    Container,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LogSidebar from "../../utils/LogSidebar"
import { keyBy } from "lodash";
import gql from "graphql-tag";
import DeployVersionButton from "./DeployVersionButton";
import DeleteVersionButton from "./DeleteVersionButton";
import DeployBetaVersionButton from "./DeployBetaVersionButton";
import moment from "moment";
import PropTypes from "prop-types";
import { patch, prerelease } from "semver";
// import EditVersionButton from "./EditVersionButton";
// import { UserContext } from "../../utils/UserProvider"

export const GET_AWA_VERSIONS = gql`
  {
    AWAVersions {
      id
      name
      createdAt
      deletedAt
      deployedAt
      description
      moduleVersions {
        id
        name
        moduleId
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        position: "relative",
    },
    title: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    subtitle: {
        fontSize: "0.8rem",
        marginLeft: theme.spacing(1),
    },
    versionActions: {
        position: "absolute",
        top: "0",
        right: "0",
        padding: theme.spacing(1),
    },
    error: {
        padding: theme.spacing(2),
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        marginBottom: theme.spacing(2)
    },
    success: {
        padding: theme.spacing(2),
        background: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        marginBottom: theme.spacing(2)
    },
    content: {
        flexBasis: "100%"
    },
    contentSmall: {
        flexBasis: "60%"
    },
    logs: {
        flexBasis: "40%"
    }
}));

AWAVersionAction.propTypes = {
    id: PropTypes.string.isRequired,
    otherError: PropTypes.object,
    success: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.any,
    children: PropTypes.node,
    setVersion: PropTypes.func.isRequired,
    setRefetch: PropTypes.func.isRequired,
    showDelete: PropTypes.bool,
    showDeploy: PropTypes.bool,
    showDeployBeta: PropTypes.bool,
};

export default function AWAVersionAction({ id, fullWidth, title, subtitle, children, setVersion, setRefetch, log, showDelete = false, showDeploy = false, showDeployBeta = false }) {
    const classes = useStyles();
    const { loading, error, data, refetch } = useQuery(GET_AWA_VERSIONS, {
        fetchPolicy: "cache-and-network",
    });
    // const user = useContext(UserContext);

    const { dicVersions, deleted } = useMemo(() => {
        if (data && data.AWAVersions && data.AWAVersions.length) {
            const dic = keyBy(data.AWAVersions, x => x.id);
            const version = dic[id];
            if (version) {
                if (setVersion) {
                    const isPatch = version && version.name && patch(version.name) !== 0;
                    setVersion({ ...version, isPatch }, data.AWAVersions);
                }
                if (setRefetch) {
                    setRefetch(() => refetch);
                }
                return {
                    dicVersions: dic,
                    deleted: version.deletedAt ? moment(version.deletedAt) : null
                }
            }
        }
        return {
            dicVersions: {},
            deleted: null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (
        <Container fullWidth style={{ maxWidth: fullWidth || log ? "100%" : "inherit" }}>
            <Paper className={classes.paper}>
                {loading && (!dicVersions || (dicVersions && !dicVersions[id])) &&
                    <Grid
                        container
                        justify="center"
                    >
                        <Grid item>
                            <CircularProgress data-testid="loading" />
                        </Grid>
                    </Grid>}
                {error && <Paper className={classes.error}>{error.message}</Paper>}
                {/* {otherError && <Paper className={classes.error}>{otherError.message}</Paper>}
                {success && <Paper className={classes.success}>{success.message}</Paper>} */}
                {data && data.AWAVersions && dicVersions[id] &&
                    <>
                        <div className={classes.versionActions} >
                            {showDeployBeta && <DeployBetaVersionButton versionId={id} disabled={deleted} />}
                            {showDeploy && !prerelease(dicVersions[id].name) && <DeployVersionButton versionId={id} disabled={deleted} />}
                            {/* {((user && user.su) || prerelease(dicVersions[id].name)) && <EditVersionButton versionId={id} disabled={deleted} />} */}
                            {showDelete && <DeleteVersionButton versionId={id} disabled={deleted} />}
                        </div>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            className={classes.title}
                        >
                            <Typography component="h1" variant="h4">
                                {title}&nbsp;{dicVersions[id] ? dicVersions[id].name : ""} {deleted && <span>(deleted)</span>}
                            </Typography>
                            <Typography variant="h6" className={classes.subtitle}>
                                {subtitle}
                            </Typography>
                        </Grid>
                        <LogSidebar
                            children={children}
                            log={log}
                        />
                    </>
                }
            </Paper>
        </Container>
    );
}
