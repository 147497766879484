import { useQuery } from "@apollo/react-hooks";
import DateFnsUtils from "@date-io/date-fns";
import {
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import dayjs from "dayjs";
import gql from "graphql-tag";
import { indexBy, prop } from "ramda";
import React, { useEffect, useState } from "react";
import { AirworkUsersDetails } from "./AirworkUsersDetails";
import { AirworkUsersSummary } from "./AirworkUserSummary";

const useStyles = makeStyles({
  table: {
    margin: "20px"
  },
  item: {
    marginTop: "20px"
  },
  paper: {
    marginBottom: "20px",
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
});

export const AirworkUsers = () => {
  const moduleEnum = {
    airwork: "airwork",
    edamage: "edamage"
  };
  const classes = useStyles();
  const [date, setDate] = useState(dayjs().format("YYYY-MM-01"));
  const [stats, setStats] = useState([]);
  const [selectedModule, setSelectedModule] = useState(moduleEnum.airwork);

  const GET_USERS = gql`
    query {
      AirworkAllUsers {
        trigram
        id
        email
        deletedAt
        isActive
      }
    }
  `;

  const GET_AIRWORK_STATS = gql`
    query($date: String!) {
      MatomoAirworkStats(date: $date) {
        user_Id
        visitor_localtime
        name
        custom_dimension_1
        custom_dimension_2
        visit_total_time
        visit_first_action_time
        visitor_seconds_since_first
        idvisit
      }
    }
  `;
  const GET_EDAMAGE_STATS = gql`
    query($date: String!) {
      MatomoEdamageStats(date: $date) {
        user_Id
        visitor_localtime
        name
        custom_dimension_1
        custom_dimension_2
        visit_total_time
        visit_first_action_time
        visitor_seconds_since_first
        idvisit
      }
    }
  `;

  const { loading: userLoading, error: userError, data: userData } = useQuery(
    GET_USERS,
    {
      fetchPolicy: "no-cache"
    }
  );

  const {
    loading: matomoEdamageLoading,
    error: matomoEdamageError,
    data: matomoEdamageData
  } = useQuery(GET_EDAMAGE_STATS, {
    fetchPolicy: "no-cache",
    variables: { date }
  });

  const {
    loading: matomoAirworkLoading,
    error: matomoAirworkError,
    data: matomoAirworkData
  } = useQuery(GET_AIRWORK_STATS, {
    fetchPolicy: "no-cache",
    variables: { date }
  });

  const matomoAirworkStat = matomoAirworkData
    ? matomoAirworkData?.MatomoAirworkStats || []
    : [];

  const matomoEdamageStat = matomoEdamageData
    ? matomoEdamageData?.MatomoEdamageStats || []
    : [];
  useEffect(() => {
    if (!selectedModule) return;
    selectedModule === moduleEnum.edamage
      ? setStats(matomoEdamageStat)
      : setStats(matomoAirworkStat);
  }, [
    matomoAirworkStat,
    matomoEdamageStat,
    moduleEnum.edamage,
    selectedModule
  ]);

  const users = userData ? userData?.AirworkAllUsers || [] : [];

  const arrayWithUniqueUser = Object.values(
    stats.reduce((acc, value) => {
      if (!acc[value.use_Id]) acc[value.user_Id] = value;
      return acc;
    }, {})
  );

  const dicUsersById = indexBy(prop("id"), users);

  const handleChangeDate = date => {
    setDate(dayjs(date).format("YYYY-MM-01"));
  };

  const isLoading = userLoading || matomoAirworkLoading || matomoEdamageLoading;
  const isError = userError || matomoAirworkError || matomoEdamageError;

  return (
    <>
      {isError && (
        <Alert severity="error">
          An error occured while fetching data :{" "}
          {userError?.message || matomoAirworkError?.message}
        </Alert>
      )}
      {isLoading && (
        <Grid container justify="center">
          <Grid item>
            <CircularProgress data-testid="loading" />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container className={classes.table}>
          <Grid xs={11}>
            <Paper className={classes.paper} elevation={3} sm={12}>
              <Grid item sm={6}>
                <h2>Module AWA</h2>
                <p>
                  Veuillez sélectionner le module AWA. Par défaut les
                  statistiques du module airwork sont affichées
                </p>
              </Grid>
              <Grid item>
                <Select
                  labelId="moduleSelect"
                  id="moduleSelect"
                  value={selectedModule}
                  label="AWA modules"
                  onChange={e => setSelectedModule(e.target.value)}
                >
                  {Object.keys(moduleEnum).map(module => {
                    return <MenuItem value={module}>{module}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Paper>
          </Grid>
          <Grid xs={11}>
            <Paper className={classes.paper} elevation={3}>
              <Grid item>
                <h2>Période de statistiques</h2>
                <p>
                  Pour limiter le volume de données, la période de statistique
                  est de 1mois.
                  <br /> Veuillez sélectionner l'année et le mois ci-dessous.
                </p>
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={["year", "month"]}
                    format="MM/yyyy"
                    id="date-picker-inline"
                    value={date}
                    onChange={handleChangeDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={11}>
            <AirworkUsersSummary
              selectedModule={selectedModule}
              uniqueUsers={arrayWithUniqueUser || []}
              dicUsersById={dicUsersById}
              style={{ margingBottom: "50px" }}
            />
          </Grid>
          <Grid item xs={11}>
            <AirworkUsersDetails
              selectedModule={selectedModule}
              matomoStat={stats}
              dicUsersById={dicUsersById}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
