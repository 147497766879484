import React from "react";
import { Router } from "@reach/router";
import Dashboard from "./Dashboard";
import NewVersion from "../awa/versions/new/NewVersion";
import AWAVersion from "../awa/versions/detail/AWAVersion";
import DeployVersion from "../awa/versions/deploy/DeployVersion";
import DeleteVersion from "../awa/versions/delete/DeleteVersion";
import ModulesRights from "../customer/modules/ModulesRights";
import AWAUrl from "../customer/url/AWAUrl";
import DeleteStage from "../awa/DeleteStage";
import AwaVersionCustomer from "../awa/AwaVersionCustomer";
import WatchLog from "../log/WatchLog";
import { Deployments } from "../aws/Deployments";
import AWATemporaryVersions from "../awa/versions/AWATemporaryVersions";
import { AirworkUsers } from "../airwork/AirworkUsers.js";
import DetailCustomers from "../detailCustomers/DetailCustomers.jsx";

export default function Main() {
  return (
    <Router>
      <Dashboard path="/" />
      <Deployments path="/deployments" />
      <NewVersion path="/awa/versions/new" />
      <AWAVersion path="/awa/versions/:id" />
      <DeployVersion path="/awa/versions/deploy/:id/:stage" />
      <DeleteStage path="/awa/delete/stage/:stage" />
      <DeleteVersion path="/awa/versions/delete/:id" />
      <ModulesRights path="/customer/modules/rights" />
      <AWAUrl path="/customer/url" />
      <AwaVersionCustomer path="/awa/versions/customer" />
      <WatchLog path="/logs/:id" />
      <AWATemporaryVersions path="/awa/temporary_versions" />
      <AirworkUsers path="/awa-users" />
      <DetailCustomers path="/detail-customers" />
    </Router>
  );
}
