import React, { useMemo, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { orderBy } from "lodash";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { compare as compareSemVer, satisfies, coerce, valid } from "semver";

const versionIsUpper = (version, limitVersion) => {
  if (!limitVersion) return true;
  return satisfies(coerce(version), `>${coerce(limitVersion)}`);
}

export const GET_MODULES = gql`
  {
    AWAModules {
      id
      name
      isMonorepo
      removedInVersion
      monorepoLastVersionWithout
      availableVersions {
        id
        name
      }
    }
  }
`;

const GET_AWA_VERSIONS_AND_MODULES = gql`
  {
    AWAVersions {
      id
      name
      createdAt
      deletedAt
      moduleVersions {
        id
        name
        moduleId
      }
    }
  }
`;

export default function ModuleVersions({ formik }) {
  const { values, handleChange, handleBlur } = formik;
  const {
    loading: modulesLoading,
    error: modulesError,
    data: modulesData,
  } = useQuery(GET_MODULES);

  const {
    loading: awaVersionsLoading,
    error: awaVersionsError,
    data: awaVersionsData,
  } = useQuery(GET_AWA_VERSIONS_AND_MODULES, {
    fetchPolicy: "cache-and-network",
  });
  const filterRemovedModules = useCallback((modules, version = values?.name) => {
    if (!modules.length) {
      return [];
    }
    const versionName = version && valid(version) ? coerce(version) : undefined;
    return modules.filter(({ removedInVersion }) => !removedInVersion || !(versionName && satisfies(versionName, `>=${removedInVersion}`)))
  }, [values.name]);
  const [versions, setVersions] = React.useState([]);
  const [useMonorepo, setUseMonorepo] = React.useState(false);

  const {
    monorepoModule,
    otherModules,
    modulesUsingMonorepo,
    moduleIdsUsingMonorepo,
  } = useMemo(() => {
    if (!modulesData?.AWAModules?.length)
      return {
        monorepoModule: null,
        otherModules: [],
        modulesIdsUsingMonorepo: [],
      };
    const filteredRemovedModules = filterRemovedModules(modulesData.AWAModules);
    const modulesUsingMonorepo = filteredRemovedModules.filter(
      (m) => m.isMonorepo === "true"
    );
    const moduleIdsUsingMonorepo = modulesUsingMonorepo.map((m) => m.id);
    const monorepoModule = filteredRemovedModules.find(
      (m) => m.name === "monorepo"
    );
    const otherModules = filteredRemovedModules.filter((m) => m.name !== "monorepo");
    return { monorepoModule, otherModules, moduleIdsUsingMonorepo, modulesUsingMonorepo };
  }, [modulesData, filterRemovedModules]);

  const updateModules = useCallback((name) => {
    const monorepoVersion = name || values.modules[monorepoModule.id] || monorepoModule.availableVersions[0]?.name;
    const latestVersionWithMonorepoVersion = name ? versions.find(({ moduleVersions }) => moduleVersions.find(({ moduleId }) => moduleId === monorepoModule.id)?.name === name) : null;
    const modules = latestVersionWithMonorepoVersion ? latestVersionWithMonorepoVersion.moduleVersions.reduce((acc, { moduleId, name }) => {
      acc[moduleId] = name;
      return acc;
    }, {}) : values.modules;
    const nonMonorepoModules = Object.entries(modules).reduce(
      (acc, [modId, version]) => {
        const isUsingMonorepo = moduleIdsUsingMonorepo.includes(modId);
        if (!isUsingMonorepo) return {
          ...acc,
          [modId]: version,
        };
        const lastVersionWithout = modulesUsingMonorepo.find(m => m.id === modId).monorepoLastVersionWithout;
        const isMonoModuleInVersion = lastVersionWithout && monorepoVersion ? versionIsUpper(monorepoVersion, lastVersionWithout) : true;
        if (!isMonoModuleInVersion) return {
          ...acc,
          [modId]: version,
        };
        return acc;
      },
      {}
    );
    handleChange({
      target: { name: "modules", value: { ...nonMonorepoModules, [monorepoModule.id]: monorepoVersion } },
    });
  }, [values.modules, handleChange, moduleIdsUsingMonorepo, modulesUsingMonorepo, monorepoModule, versions])

  useEffect(() => {
    if (!modulesData?.AWAModules) return;
    if (useMonorepo) {
      updateModules();
      return;
    }
    const currentModules = values.modules;
    const newModules = filterRemovedModules(modulesData.AWAModules).reduce((acc, module) => {
      if (module?.name === "monorepo") return acc;

      const currentModule = currentModules[module.id];
      if (currentModule) {
        return { ...acc, [module.id]: currentModule };
      }

      return {
        ...acc,
        [module.id]: module.availableVersions[0]?.name,
      };
    }, {});

    handleChange({
      target: { name: "modules", value: newModules },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useMonorepo]);

  useEffect(() => {
    if (values.fromVersion && monorepoModule && versions.length) {
      const version = versions.find((v) => v.name === values.fromVersion);
      if (version) {
        const versionMonorepo = version.moduleVersions.find(({ moduleId }) => moduleId === monorepoModule.id);
        setUseMonorepo(!!versionMonorepo);
      }
    }
  }, [values.fromVersion, monorepoModule, versions]);

  useEffect(() => {
    if (!awaVersionsData || !awaVersionsData.AWAVersions) {
      return;
    }
    setVersions(
      awaVersionsData.AWAVersions.filter(
        (version) => !version.deletedAt
      ).sort((a, b) => compareSemVer(b.name, a.name))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awaVersionsData]);

  useEffect(() => {
    if (!modulesData || !modulesData.AWAModules) {
      return;
    }
    filterRemovedModules(modulesData.AWAModules).forEach(({ id, name, availableVersions }) => {
      const version = availableVersions[0];
      if (!values.modules[id] && (name !== "monorepo" || useMonorepo)) {
        const event = {
          target: { name: `modules.${id}`, value: version?.name },
        };
        handleChange(event);
      }
      if (!values.dicModules[id]) {
        const event = {
          target: { name: `dicModules.${id}`, value: name },
        };
        handleChange(event);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulesData]);

  useEffect(() => {
    const hasLatest = versions.find((v) => v.name === "LATEST");
    if (
      !modulesData ||
      !modulesData.AWAModules ||
      !versions.length ||
      hasLatest
    ) {
      return;
    }
    const latest = {
      id: "latest",
      name: "LATEST",
      moduleVersions: [],
    };
    filterRemovedModules(modulesData.AWAModules).forEach(({ id, availableVersions }) => {
      latest.moduleVersions.push({
        name: availableVersions[0]?.name,
        moduleId: id,
      });
    });
    setVersions([latest, ...versions]);
  }, [modulesData, versions, filterRemovedModules]);

  const sortedAndFilteredModules = useMemo(() => {
    return orderBy(otherModules, "name").map((module) => {
      const sortedModuleVersions = module.availableVersions
        .sort((a, b) =>
          compareSemVer(coerce(b.name), coerce(a.name))
        );
      const shouldUseMonorepo = useMonorepo
        && module.isMonorepo === "true"
        && values.modules[monorepoModule.id]
        && (!module.monorepoLastVersionWithout
          || versionIsUpper(values.modules[monorepoModule.id], module.monorepoLastVersionWithout));
      return {
        ...module,
        id: parseInt(module.id),
        sortedModuleVersions,
        shouldUseMonorepo,
      }
    });
  }, [otherModules, useMonorepo, values.modules, monorepoModule]);

  if (modulesLoading || awaVersionsLoading)
    return (
      <Grid container justify="center">
        <Grid item>
          <CircularProgress data-testid="loading" />
        </Grid>
      </Grid>
    );
  if (modulesError) return modulesError.message;
  if (awaVersionsError) return awaVersionsError.message;
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Copy from version
      </Typography>
      <Grid container spacing={3} style={{ marginBottom: "20px" }}>
        <Grid item xl={12}>
          <FormControl fullWidth>
            <Select
              id="fromVersion"
              name="fromVersion"
              value={values.fromVersion}
              fullWidth
              onChange={(e) => {
                const version = versions.find((v) => v.name === e.target.value);
                if (version && version.moduleVersions) {
                  handleChange(e);
                  version.moduleVersions.forEach(({ name, moduleId }) => {
                    const event = {
                      target: { name: `modules.${moduleId}`, value: name },
                    };
                    handleChange(event);
                  });
                }
              }}
            >
              {versions.map(({ id, name }) => (
                <MenuItem key={id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom>
        Modules
      </Typography>
      <Grid container spacing={3}>
        <Grid item xl={4}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                id="toggleMonorepo"
                name="toggleMonorepo"
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={useMonorepo}
                onChange={() => setUseMonorepo(!useMonorepo)}
                value={useMonorepo}
              />
            }
            label={`Toggle monorepo`}
          />
        </Grid>
        <Grid item sm={8} xl={8}>
          {useMonorepo && monorepoModule && (
            <FormControl fullWidth required>
              <InputLabel htmlFor={monorepoModule.name}>
                {monorepoModule?.name}
              </InputLabel>
              <Select
                fullWidth
                name={`modules.${monorepoModule.id}`}
                value={values.modules[monorepoModule.id] || ""}
                onChange={(e) => {
                  const event = {
                    target: { name: `fromVersion`, value: null },
                  };
                  handleChange(event);
                  updateModules(e.target.value);
                }}
                onBlur={handleBlur}
              >
                {monorepoModule.availableVersions
                  .sort((a, b) => compareSemVer(coerce(b.name), coerce(a.name)))
                  .map(({ id, name }) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        {(sortedAndFilteredModules || []).map(({ sortedModuleVersions, shouldUseMonorepo, id, name }) => {
          return (
            <Grid key={id} item sm={6} xl={4}>
              <FormControl fullWidth required>
                <InputLabel htmlFor={name}>
                  {name}
                  {shouldUseMonorepo && "-monorepo"}
                </InputLabel>
                <Select
                  disabled={shouldUseMonorepo}
                  fullWidth
                  name={`modules.${id}`}
                  value={
                    shouldUseMonorepo ? "" : (values.modules[id] || sortedModuleVersions[0]?.name)
                  }
                  onChange={(e) => {
                    const event = {
                      target: { name: `fromVersion`, value: null },
                    };
                    handleChange(event);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                >
                  {sortedModuleVersions.map(({ id, name }) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
