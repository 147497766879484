import React from "react";
import PropTypes from "prop-types";
import BackupIcon from "@material-ui/icons/Backup";
import BaseIconTextButton from '../BaseIconTextButton';
import { navigate } from "@reach/router";
export default function DeployVersionButton({ versionId, disabled }) {
  return (
    <BaseIconTextButton
      label={"Deploy"}
      tooltip={"Deploy"}
      disabled={disabled}
      icon={<BackupIcon />}
      onClick={() => navigate(`/awa/versions/deploy/${versionId}/PROD`)}
    />
  );
}

DeployVersionButton.propTypes = {
  disabled: PropTypes.any,
  versionId: PropTypes.string.isRequired
};