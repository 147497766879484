import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  CircularProgress,
  Switch
} from "@material-ui/core";
import { Formik } from "formik";
import Yup, {
  REQUIRED_FIELD_MESSAGE,
  NON_WORD_CHARACTERS
} from "../../../utils/CustomYup";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/styles";
import AWAVersionAction from "../AWAVersionAction";
import ProgressButton from "../../../utils/ProgressButton";
import CustomersVersion from "../detail/CustomersVersion";
import ModulesVersion from "../detail/ModulesVersion";
import PropTypes from "prop-types";
import Errors from "../../../utils/Errors";
import Success from "../../../utils/Success";
import Warning from "../../../utils/Warning";
import Info from "../../../utils/Info";
import useAddLogMutation from "../../../log/useAddLogMutation";
import BaseExpansionPanel from "./BaseExpansionPanel";
import TypingDurationTextField from "./TypingDurationTextField";
import LastDeploy from "../detail/LastDeploy";
import { UserContext } from "../../../utils/UserProvider";
import useParseModules from "../../../utils/useParseModules";

const AWACLIENTID = "25";
const EDATASERVERID = "23";

const DEPLOY_VERSION = gql`
  mutation deployAWAVersion($deployVersion: AWADeployVersionInput!) {
    deployAWAVersion(deployVersion: $deployVersion)
  }
`;
const BETA_EXIST = gql`
  mutation betaExist($name: String!) {
    betaExist(name: $name)
  }
`;

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
    flexWrap: "no-wrap"
  },
  grow: {
    flexGrow: 1
  },
  info: {
    fontSize: "0.8rem"
  },
  headerPanel: {
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2)"
  },
  messageEdata: {
    marginTop: theme.spacing(2)
  }
}));

DeployVersion.propTypes = {
  id: PropTypes.string.isRequired,
  stage: PropTypes.string
};
const maxStageLength = 16;

export default function DeployVersion({ id, stage = "PROD" }) {
  const classes = useStyles();
  const [includedCustomers, setIncludedCustomers] = useState([]);
  const [excludedModules, setExcludedModules] = useState([]);
  const [version, setVersion] = useState({});
  const [noModule, setNoModule] = useState(false);
  const [deployAsMajorMinor, setDeployAsMajorMinor] = useState(false);
  const [deployModulesMutation, deployStatus] = useMutation(DEPLOY_VERSION);
  const [migrateDBMutation, migrateStatus] = useMutation(DEPLOY_VERSION);
  const [betaExist, betaExistStatus] = useMutation(BETA_EXIST);
  const [registerLog, registerLogStatus] = useAddLogMutation();
  const [customStageVerified, setCustomStageVerified] = useState("");
  const [migrateStage, setMigrateStage] = useState(stage);

  const isBeta = stage === "BETA";
  const isPatch = version.isPatch;
  const [deployAdmin, setDeployAdmin] = useState(false);
  const [deployStage, setDeployStage] = useState(true);
  const [customerOnlyProd, setCustomerOnlyProd] = useState(true);
  const { su } = useContext(UserContext);

  const {
    modules,
    modulesCount,
    excludedModulesCount,
    isMonorepoExcluded,
    modulesLoading
  } = useParseModules({
    versionModules: version.moduleVersions,
    versionName: version.name,
    excludedModules,
    setExcludedModules
  });

  const stages = [
    {
      label: "PROD",
      checked: deployStage,
      onChange: () => setDeployStage(!deployStage)
    },
    {
      label: "ADMIN",
      checked: deployAdmin,
      onChange: () => setDeployAdmin(!deployAdmin)
    }
  ];

  const filterCVS = useCallback(
    v => {
      return customerOnlyProd ? v.stage.startsWith(stage) : true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [customerOnlyProd, stage]
  );

  const initialValues = useMemo(() => {
    return isBeta
      ? {
          customStage: "",
          customerTrigram: "",
          restoreFromStage: false,
          excludedModules: []
        }
      : {
          customerTrigram: "",
          excludedModules: []
        };
  }, [isBeta]);
  const versionSchema = useMemo(() => {
    return isBeta
      ? Yup.object().shape({
          customStage: Yup.string()
            .required(REQUIRED_FIELD_MESSAGE)
            .test("start-beta", "Must start with BETA", value =>
              /^beta/i.test(value)
            )
            .test(
              "length",
              `Must be maximum ${maxStageLength} characters`,
              value => value && value.length <= maxStageLength
            )
            .test(
              "contain-underscore",
              "Must not contain underscore",
              value => !/_/i.test(value)
            )
            .nonWordCharacters(NON_WORD_CHARACTERS),
          customerTrigram: Yup.string()
            .when("restoreFromStage", {
              is: val => !!val,
              then: Yup.string().required(REQUIRED_FIELD_MESSAGE),
              otherwise: Yup.string()
            })
            .nonWordCharacters(NON_WORD_CHARACTERS),
          restoreFromStage: Yup.bool(),
          excludedModules: Yup.array()
        })
      : Yup.object().shape({
          excludedModules: Yup.array(),
          customerTrigram: Yup.string().nonWordCharacters(NON_WORD_CHARACTERS)
        });
  }, [isBeta]);

  function noModuleProcess() {
    setNoModule(!noModule);
    if (!noModule) {
      setDeployAdmin(false);
      setDeployStage(false);
      setExcludedModules([
        ...(modules.excludedModules || []).map(m => m.id),
        ...(modules.monorepoModules || []).map(m => m.id)
      ]);
      return;
    } else {
      setDeployStage(true);
      setExcludedModules([...(modules.excludedModules || []).map(m => m.id)]);
      return;
    }
  }
  function hasNoModuleSelected(mod) {
    return mod.length === modulesCount;
  }
  function awaClientAlwaysSelected(modules) {
    if (su) {
      return modules;
    }
    return hasNoModuleSelected(modules) ||
      (modules.length === modulesCount - 1 && !modules.includes(AWACLIENTID))
      ? modules
      : modules.filter(mId => !(mId === AWACLIENTID));
  }
  const vId = version.id;
  useEffect(() => {
    if (version && version.moduleVersions) {
      noModuleProcess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vId]);

  const label = useMemo(() => {
    return isBeta ? "Deploy Beta" : isPatch ? "Deploy Patch" : "Deploy Version";
  }, [isBeta, isPatch]);

  async function isValid(formik) {
    const errors = await formik.validateForm();
    if (Object.entries(errors).length !== 0) {
      formik.setStatus({ errors });
    }
    return (
      !Object.entries(errors).length &&
      (!isBeta ||
        (isBeta && !!betaExistStatus.data && !betaExistStatus.loading))
    );
  }

  async function migrateDB(formik) {
    const valid = await isValid(formik);
    if (!valid) {
      return;
    }
    const registeredLog = await registerLog({
      variables: {
        log: {
          taskReference: `deploy-${id}`,
          title: `${label} - ${version.name}`,
          status: "INFO"
        }
      }
    });
    return migrateDBMutation({
      variables: {
        deployVersion: {
          includedCustomers,
          restoreFromStage:
            formik.values.restoreFromStage === true ? "PROD" : null,
          channelId: registeredLog.data.addLog.id,
          versionId: id,
          stage: migrateStage,
          customStage: formik.values.customStage,
          customerTrigram: formik.values.customerTrigram,
          excludedModules,
          deployAsMajorMinor,
          migrateDatabases: true,
          deployAdmin: false,
          deployTraining: false,
          deployStage: false
        }
      }
    });
  }

  async function deployModules(formik) {
    const valid = await isValid(formik);
    if (!valid) {
      return;
    }
    const registeredLog = await registerLog({
      variables: {
        log: {
          taskReference: `deploy-${id}`,
          title: `${label} - ${version.name}`,
          status: "INFO"
        }
      }
    });

    return deployModulesMutation({
      variables: {
        deployVersion: {
          includedCustomers,
          restoreFromStage: null,
          channelId: registeredLog.data.addLog.id,
          versionId: id,
          stage,
          customStage: formik.values.customStage,
          customerTrigram: "",
          excludedModules,
          deployAsMajorMinor,
          migrateDatabases: false,
          deployAdmin,
          deployTraining: false,
          deployStage
        }
      }
    });
  }

  const renderStagesFormLabel = ({
    checked,
    onChange,
    label,
    disabled = false
  }) => (
    <FormControlLabel
      onClick={e => {
        e.stopPropagation();
      }}
      control={
        <Switch
          disabled={disabled}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
          checked={checked}
          onChange={onChange}
          onClick={e => {
            e.stopPropagation();
          }}
          value={`switch-migrate-${label}`}
        />
      }
      label={`${label}`}
    />
  );

  return (
    <AWAVersionAction
      title={
        isBeta
          ? "Deploy Beta AWA Version"
          : isPatch
          ? "Deploy Patch "
          : "Deploy AWA Version"
      }
      id={id}
      setVersion={(version, versions) => {
        setVersion(version);
      }}
      log={registerLogStatus}
      fullWidth
      otherError={deployStatus.error || migrateStatus.error}
      subtitle={
        <LastDeploy
          show={!isBeta}
          stage={stage}
          deployedAt={version ? version.deployedAt : null}
        />
      }
    >
      {version.id && (
        <Formik initialValues={initialValues} validationSchema={versionSchema}>
          {formik => {
            const switchMigrate = stageTo => {
              if (!migrateStage || migrateStage !== stageTo)
                setMigrateStage(stageTo);
              if (migrateStage === stageTo) setMigrateStage(null);
              if (!migrateStage || stageTo === "PROD")
                formik.handleChange({
                  target: {
                    name: "restoreFromStage",
                    value: false
                  }
                });
            };
            return (
              <>
                <BaseExpansionPanel type={"General"}>
                  <Grid item xl={12}>
                    {isBeta ? (
                      <>
                        <TypingDurationTextField
                          required
                          id="customStage"
                          name="customStage"
                          label="Beta Stage name"
                          fullWidth
                          value={formik.values.customStage}
                          onChange={formik.handleChange}
                          onFinishTyping={async () => {
                            const stage = formik.values.customStage;
                            if (
                              stage &&
                              stage.length > 4 &&
                              stage.startsWith("BETA") &&
                              !formik.errors.customStage
                            ) {
                              await betaExist({ variables: { name: stage } });
                              setCustomStageVerified(stage);
                            }
                          }}
                          error={formik.errors.customStage}
                        />
                        <Errors messages={formik.errors.customStage} />
                        {betaExistStatus.loading && (
                          <CircularProgress data-testid="loading" />
                        )}
                        {betaExistStatus.data &&
                          betaExistStatus.data.betaExist === true && (
                            <Warning
                              showIcon={true}
                              messages={`${customStageVerified} already exist make sure you know what you are doing`}
                            />
                          )}
                        {betaExistStatus.data &&
                          betaExistStatus.data.betaExist === false && (
                            <Success
                              showIcon={true}
                              messages={`${customStageVerified} name is new`}
                            />
                          )}
                        {isBeta && betaExistStatus.data && (
                          <span>
                            <br />
                            AWA url :{" "}
                            <a
                              target={"_blank"}
                              href={`http://ads-awa-${customStageVerified.toLowerCase()}.s3-website.eu-west-3.amazonaws.com/`}
                            >{`http://ads-awa-${customStageVerified.toLowerCase()}.s3-website.eu-west-3.amazonaws.com/`}</a>
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {isPatch ? (
                          deployAsMajorMinor ? (
                            <Typography>
                              This is a Patch Version treated as Minor/Major
                              Version it will be deploy on PROD.
                            </Typography>
                          ) : (
                            <Typography>
                              This is a Patch Version it will be deploy to all
                              selected Customers Stage/Version.
                            </Typography>
                          )
                        ) : (
                          <Typography>
                            This is a Minor/Major Version it will be deploy on
                            PROD.
                          </Typography>
                        )}
                      </>
                    )}
                    {!isBeta && isPatch && (
                      <FormControlLabel
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        control={
                          <Switch
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            checked={deployAsMajorMinor}
                            onChange={() => {
                              setDeployAsMajorMinor(!deployAsMajorMinor);
                              formik.handleChange({
                                target: {
                                  name: "customerTrigram",
                                  value: ""
                                }
                              });
                            }}
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            value={`switch-deploy-as-major-minor`}
                          />
                        }
                        label={`Deploy as Major/Minor`}
                      />
                    )}
                  </Grid>
                </BaseExpansionPanel>
                {isPatch && !deployAsMajorMinor && !isBeta && (
                  <BaseExpansionPanel
                    summary={() => (
                      <Grid container wrap={"nowrap"} alignItems="center">
                        <Grid item className={classes.grow}>
                          <Typography>Customers</Typography>
                        </Grid>
                        <Grid item alignSelf="flex-end">
                          <FormControlLabel
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            control={
                              <Switch
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox"
                                }}
                                checked={customerOnlyProd}
                                onChange={() => {
                                  setCustomerOnlyProd(!customerOnlyProd);
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                value={`switch-deploy-only-prod`}
                              />
                            }
                            label={`Show only ${stage}`}
                          />
                        </Grid>
                      </Grid>
                    )}
                    type={"Customers"}
                  >
                    {version && version.name && (
                      <CustomersVersion
                        filter={filterCVS}
                        versionName={version.name}
                        includedCustomers={includedCustomers}
                        onChange={e => setIncludedCustomers(e)}
                        select={!deployAsMajorMinor}
                      />
                    )}
                  </BaseExpansionPanel>
                )}
                <BaseExpansionPanel
                  summary={() => (
                    <Grid container wrap={"nowrap"} alignItems="center">
                      <Grid item className={classes.grow}>
                        <Typography>
                          {version && version.moduleVersions
                            ? `Modules (${modulesCount +
                                excludedModulesCount -
                                excludedModules.length}/${modulesCount})`
                            : `Modules`}
                        </Typography>
                      </Grid>
                      <Grid item alignSelf="flex-end">
                        {!isBeta && (!isPatch || deployAsMajorMinor) && (
                          <>
                            {stages.map(stage =>
                              renderStagesFormLabel({
                                ...stage,
                                disabled: noModule
                              })
                            )}
                          </>
                        )}
                        <FormControlLabel
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          control={
                            <Switch
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              checked={noModule}
                              onChange={() => {
                                noModuleProcess();
                              }}
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              value={`switch-no-module`}
                            />
                          }
                          label={`No module`}
                        />
                      </Grid>
                    </Grid>
                  )}
                  type={"Modules"}
                  direction={"column"}
                >
                  {version && (
                    <ModulesVersion
                      excludedModules={excludedModules}
                      modules={modules}
                      loading={modulesLoading}
                      isMonorepoExcluded={isMonorepoExcluded}
                      select={true}
                      onChange={e => {
                        setNoModule(false);
                        if (e.length < modulesCount + excludedModulesCount) {
                          setDeployStage(true);
                        } else if (deployStage) {
                          setDeployStage(false);
                        }
                        return setExcludedModules(awaClientAlwaysSelected(e));
                      }}
                    />
                  )}
                  {
                    <Info
                      className={classes.messageEdata}
                      showIcon={true}
                      messages={`Awa-client is now deployed on each patch because we insert the AWA version '${version.name}'`}
                    />
                  }
                  {!excludedModules.includes(EDATASERVERID) && (
                    <Warning
                      className={classes.messageEdata}
                      showIcon={true}
                      messages={
                        "Deploying edata-server will migrate edata-server base"
                      }
                    />
                  )}
                  <ProgressButton
                    className={classes.buttons}
                    label={`Deploy modules${
                      !isBeta && isPatch && !deployAsMajorMinor
                        ? " TO SELECTED STAGES"
                        : ""
                    }`}
                    action={async () => {
                      await deployModules(formik);
                    }}
                    busy={deployStatus.loading}
                    disabled={
                      noModule ||
                      hasNoModuleSelected(excludedModules) ||
                      !!version.deletedAt ||
                      migrateStatus.loading
                    }
                  />
                </BaseExpansionPanel>
                <BaseExpansionPanel
                  type={"Databases"}
                  direction={"column"}
                  summary={() => (
                    <Grid container wrap={"nowrap"} alignItems="center">
                      <Grid item className={classes.grow}>
                        <Typography>Databases</Typography>
                      </Grid>
                      {!isBeta && (
                        <Grid item alignSelf="flex-end">
                          {stages.map(({ label }) =>
                            renderStagesFormLabel({
                              label,
                              onChange: () => switchMigrate(label),
                              checked: migrateStage === label
                            })
                          )}
                        </Grid>
                      )}
                    </Grid>
                  )}
                >
                  <Grid item>
                    <Info
                      className={classes.messageEdata}
                      showIcon={true}
                      messages={"This will not migrate edata-server base"}
                    />
                    {!(!isBeta && isPatch && !deployAsMajorMinor) && (
                      <TextField
                        id="customerTrigram"
                        name="customerTrigram"
                        label="Customer trigram"
                        fullWidth
                        value={formik.values.customerTrigram}
                        onChange={formik.handleChange}
                        error={formik.errors.customerTrigram}
                      />
                    )}
                    <Errors messages={formik.errors.customerTrigram} />
                    {(isBeta || (migrateStage && migrateStage !== "PROD")) && (
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            id="restoreFromStage"
                            name="restoreFromStage"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            checked={!!formik.values.restoreFromStage}
                            onChange={formik.handleChange}
                            value={!!formik.values.restoreFromStage}
                          />
                        }
                        label={`Restore from PROD`}
                      />
                    )}
                  </Grid>
                  {
                    <ProgressButton
                      className={classes.buttons}
                      label={`Create / Migrate ${
                        !isBeta && isPatch && !deployAsMajorMinor
                          ? "SELECTED CUSTOMERS "
                          : " "
                      }databases`}
                      action={async () => {
                        await migrateDB(formik);
                      }}
                      busy={migrateStatus.loading}
                      disabled={
                        (!isBeta &&
                          deployAsMajorMinor &&
                          !formik.values.customerTrigram) ||
                        (isBeta && !formik.values.customerTrigram) ||
                        !!version.deletedAt ||
                        deployStatus.loading
                      }
                    />
                  }
                </BaseExpansionPanel>
              </>
            );
          }}
        </Formik>
      )}
    </AWAVersionAction>
  );
}
